import { useState, useRef, useEffect } from "react";
import {
	Card,
	CardContent,
	Menu,
	MenuItem,
	Divider,
	Tabs,
	Tab,
	IconButton,
	Typography,
	Grid,
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	DialogTitle,
	Box,
} from "@mui/material";
import EditMusic from "../../images/edit music.svg";
import MenuDots from "../../images/menu dots verticle.svg";
import SetProfileMusicDialog from "./setProfileMusicDialog";
import CustomAudioPlayer from "../../pages/CustomAudioPlayer";
import Share from "../../images/share1.svg";
import RemoveProfile from "../../images/profile-remove1.svg";
import Flag from "../../images/flag1.svg";
import { SocialUSerDetails } from "../../types/types";
import { useUser } from "../../context/UserContext";
import ReportBlockUser from "../commonComponents/ReportBlockUser";
import VerifyAccount from "../../dialogBox/VerifyAccount";
import PlayIcon from "../../images/play-circlebio.svg";
import { useAlertStatus } from "../../context/AlertStatusContext";
import SettingsAlert from "../../pages/SettingsAlert";
import i18n from "../../l10n/strings.json";
import RightArrow from "../../images/right arrow.svg";
import ShareProfile from "../Messages/shareprofileinmessages/ShareProfileInMessages";
import { Link, useNavigate } from "react-router-dom";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";


const TabsMenuSelf = [
	{
		label: "My Activities",
		id: "myActivities",
		disabled: false,
	},
	{
		label: "My Music",
		id: "myMusic",
		disabled: false,
	},
	{
		label: "My Pictures",
		id: "pictures",
		disabled: false,
	},
	{
		label: "Calendar",
		id: "calendar",
		disabled: false,
	},
];

const TabsMenu = [
	{
		label: "Activity",
		id: "myActivities",
		disabled: false,
	},
	{
		label: "Music",
		id: "myMusic",
		disabled: false,
	},
	{
		label: "Pictures",
		id: "pictures",
		disabled: false,
	},
	{
		label: "Calendar",
		id: "calendar",
		disabled: false,
	},
];

interface Props {
	userDetails: SocialUSerDetails;
	selectedTab: any;
	handleChange: any;
	setIsLoading: any;
	getUserDetails: any;
}

const BriefBio = (props: Props) => {
	const SelfDetails = useUser();
	const { handleChange, selectedTab } = props;
	const { updateAlertStatus } = useAlertStatus();
	const [musicTemp, setMusicTemp] = useState("");
	// const [musicTemp, setMusicTemp] = useState('https://dl.espressif.com/dl/audio/ff-16b-2c-44100hz.mp3');
	const [musicOpen, setMusicOpen] = useState(false);
	const buttonRef = useRef(null);
	const [open, setOpen] = useState(false);
	const token = localStorage.getItem("sessionId");
	const user = props?.userDetails?.privacySettings?.userProfile;
	const activationStatus = user?.user?.activated;
	const permissions = props.userDetails.privacySettings
		? props.userDetails.privacySettings
		: {};
	const viewer = props.userDetails.loggedInUser?.ViewerStatus;
	const loggedInUsersReportsOnViewingUserProfile =
		props.userDetails.loggedInUsersReportsOnViewingUserProfile;
	const [musicUrl, setMusicUrl] = useState<any>(props.userDetails.profileMusic);
	const [openReport, setOpenReport] = useState(false);
	const [openUnblockDialog, setOpenUnblockDialog] = useState(false);
	const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	const userData = useUser();
	const authorities = userData.authorities && userData.authorities.map((authority: { name: string }) => authority.name);

	useEffect(() => {
		setMusicUrl(props.userDetails.profileMusic);
	}, [props]);
	const [openShareProfileDialog, setOpenShareProfileDialog] = useState(false);
	const [profileLink, setProfileLink] = useState<any>("");
	const navigate = useNavigate();

	const handleCloseReport = () => {
		setOpenReport(false);
	};

	const handleClose = () => {
		setOpen(false);
		setOpenUnblockDialog(false);
	};

	const handleDialogClose = () => {
		setMusicOpen(false);
	};
	const toUnblockUser = {
		userProfile: { id: SelfDetails.userProfileId },
		blockedUser: user?.id,
		blockedUserName: `${user?.user?.firstName} ${user?.user?.lastName}`,
	};

	const sendConnectionRequest = (
		requestTo: number | undefined,
		requestStatus: string
	) => {
		try {
			props.setIsLoading(true);
			fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					requestFrom: {
						id: SelfDetails.userProfileId,
					},
					requestTo: {
						id: requestTo,
					},
					connectionStatus: requestStatus,
				}),
			})
				.then((res) => {
					if(res.status === 502){
						navigate('/defaultPage')
					} else if (res.status > 400) {
						throw new Error("api_error");
					} else {
						props.getUserDetails();
						return res.json();
					}
				})
				.then((data) => {
				})
				.catch((err) => {
				})
				.finally(() => {
					props.setIsLoading(false);
				});
		} catch (error) {
			props.setIsLoading(false);
		}
	};

	const handleCopyLink = () => {
		navigator.clipboard.writeText(
			`${process.env.REACT_APP_BASE_URL_FRONTEND}/userProfile/${user?.id}`
		);
		updateAlertStatus(true, true, "Link copied to clipboard");
		setOpen(!open);
	};

	const handleShareProfileDialog = () => {
		setOpenShareProfileDialog(false);
	};
	const handleGetProfileLink = () => {
		const generateLink = `${process.env.REACT_APP_BASE_URL_FRONTEND}/userProfile/${user?.nickname}`;
		navigator.clipboard.writeText(generateLink);
		setProfileLink(generateLink);
		setOpenShareProfileDialog(true);
	};

	return (
		<>
			<SettingsAlert />
			<Card
				sx={{
					minWidth: 300,
					flexGrow: 1,
					minHeight: "189px",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					mt: 3,
					borderRadius: "12px",
				}}
			>
				<CardContent sx={{ alignItems: "center" }}>
					<Grid container columns={{ xs: 6, sm: 12, md: 12 }}>
						<Grid
							item
							xs={1.5}
							sx={{
								flexBasis: { md: "115px !important" },
								maxWidth: { md: "115px !important" },
							}}
						>
							<Typography
								variant="body1"
								sx={{ fontWeight: 500, fontSize: "24px" }}
							>
								Brief Bio
							</Typography>
						</Grid>
						{(!isAdmin &&
							viewer !== "SELF" &&
							permissions?.viewProfilePermission ===
								"No one can view profile. Only me") ||
						(viewer === "NOT_IN_CONNECTION" &&
							permissions?.viewProfilePermission ===
								"Only my connections can view full profile") ? (
							""
						) : (
							<>
								{musicUrl ? (
									<Grid item xs={4.3}>
										{/* <Typography variant="body2" sx={{ px: 2 }}> */}
										{/* <CustomAudioPlayer audioUrl={ musicUrl.includes('https://') ? musicUrl : `${process.env.REACT_APP_PICTURE_URL}${musicUrl}`}
                                            displayDirectly={true}
                                            activeMenu=""
                                            isInHome={true}
                                        /> */}
										{/* {musicTemp+'------------'} */}
										{/* </Typography> */}

										<audio
											crossOrigin="anonymous"
											controls
											controlsList="nodownload noremoteplayback noplaybackrate"
										>
											<source
												src={
													musicUrl.includes("https://")
														? musicUrl
														: `${process.env.REACT_APP_PICTURE_URL}${musicUrl}`
												}
												type="audio/mpeg"
											/>
										</audio>
									</Grid>
								) : (
									<Grid
										container
										item
										xs={viewer === "SELF" ? 4.8 : 5}
										sx={{
											background: "#EDEDED !important",
											borderRadius: "19px",
											height: "38px",
										}}
									>
										<Grid
											item
											xs={1.5}
											sx={{ textAlign: "center", alignSelf: "center" }}
										>
											<IconButton
												disabled
												sx={{ width: "28px", height: "28px", mt: "2px" }}
											>
												<img
													src={PlayIcon}
													height={28}
													width={28}
													style={{ marginBottom: "3px" }}
												/>
											</IconButton>
										</Grid>
										<Grid
											item
											xs={10.5}
											sx={{ textAlign: "center", alignSelf: "center" }}
										>
											<Typography sx={{ fontSize: "12px", color: "#888888" }}>
												{viewer === "SELF"
													? "No music selected. Select edit profile to add music."
													: "No music selected."}
											</Typography>
										</Grid>
									</Grid>
								)}
								{  SelfDetails.userAccountStatus !== "SUSPEND" &&
								viewer === "SELF" ? (
									<Grid
										item
										xs={1}
										sx={{ textAlign: "center", alignSelf: "center" }}
									>
										<IconButton aria-label="Edit" size="small">
											<img
												src={EditMusic}
												alt="Edit"
												style={{ height: "24px", width: "24px" }}
												onClick={() => setMusicOpen(true)}
											/>
										</IconButton>
										<SetProfileMusicDialog
											musicOpen={musicOpen}
											handleClose={handleDialogClose}
											profileMusic={props.userDetails.profileMusic}
											musicTemp={musicTemp}
											selectedMusicId={props.userDetails.profileMusicId}
											setMusicTemp={setMusicTemp}
											getUserDetails={props.getUserDetails}
										/>
									</Grid>
								) : (
									""
								)}
								<Grid
									item
									xs={viewer === "SELF" && musicUrl === null ? 4.7 : 4.7}
									sx={{ textAlignLast: "end" }}
								>
									{SelfDetails.userAccountStatus !== "SUSPEND" &&
									viewer !== "SELF" ? (
										<IconButton
											color="primary"
											size="small"
											sx={{ ml: "auto" }}
											aria-haspopup="true"
											aria-expanded={open ? "true" : undefined}
											ref={buttonRef}
											onClick={() => {
												setOpen(!open);
											}}
										>
											<img
												src={MenuDots}
												alt="Edit"
												style={{ height: "24px", width: "24px" }}
											/>
										</IconButton>
									) : (
										""
									)}
								</Grid>
							</>
						)}
					</Grid>
					<Menu
						id="basic-menu"
						anchorEl={buttonRef.current}
						open={open}
						onClose={handleClose}
						aria-labelledby="basic-demo-button"
					>
						{permissions.shareMyProfilePermission ===
							"Anyone can share profile" ||
						(viewer === "IN_CONNECTION" &&
							permissions.shareMyProfilePermission ===
								"Only my connections can share my profile") ? (
							<MenuItem onClick={() => handleCopyLink()}>
								Copy Link To Profile
							</MenuItem>
						) : (
							""
						)}

						{permissions.shareMyProfilePermission ===
							"Anyone can share profile" ||
						(viewer === "IN_CONNECTION" &&
							permissions.shareMyProfilePermission ===
								"Only my connections can share my profile") ? (
							<MenuItem onClick={() => handleGetProfileLink()}>
								Share Profile in Message
							</MenuItem>
						) : (
							""
						)}
						{viewer === "IN_CONNECTION" ? (
							<MenuItem
								onClick={() => setRemoveConfirmation(true)}
								// onClick={() => sendConnectionRequest(user?.id, 'REMOVED')}
							>
								Remove connection
							</MenuItem>
						) : (
							""
						)}
						{viewer === "UNBLOCK" ? (
							<>
								<MenuItem onClick={() => setOpenUnblockDialog(true)}>
									Unblock
								</MenuItem>
								<VerifyAccount
									open={openUnblockDialog}
									handleClose={handleClose}
									unblock
									toUnblockUser={toUnblockUser}
									getUserDetails={props.getUserDetails}
									source="sideBar"
								/>
							</>
						) : (
							<>
								<MenuItem onClick={() => setOpenReport(true)}>
									Report/Block User
								</MenuItem>
								<ReportBlockUser
									open={openReport}
									loggedInUsersReportsOnViewingUserProfile={
										loggedInUsersReportsOnViewingUserProfile
									}
									handleClose={handleCloseReport}
									setLoading={props.setIsLoading}
									userProfileId={user?.id}
									getUserDetails={props.getUserDetails}
									userName={`${user?.user?.firstName} ${user?.user?.lastName}`}
								/>
							</>
						)}
					</Menu>
				</CardContent>

				{!isAdmin &&
					(activationStatus === false ? (
						<Typography
							variant="body2"
							sx={{
								fontSize: "16px",
								color: "#FF0000", // Red color to highlight terminated status
								ml: 2,
								mb: 1,
								textAlignLast: "center",
							}}
						>
							This profile is terminated
						</Typography>
					) : (viewer !== "SELF" &&
							permissions?.viewProfilePermission ===
								"No one can view profile. Only me") ||
					  (viewer === "NOT_IN_CONNECTION" &&
							permissions?.viewProfilePermission ===
								"Only my connections can view full profile") ? (
						<Typography
							variant="body2"
							sx={{
								fontSize: "16px",
								color: "#5B5B65",
								ml: 2,
								mb: 1,
								textAlignLast: "center",
							}}
						>
							This Profile is private
						</Typography>
					) : (
						<Typography
							variant="body2"
							sx={{ fontSize: "16px", color: "#5B5B65", ml: 2, mb: 1 }}
						>
							{user?.profileBio
								? user?.profileBio
								: viewer === "SELF"
								? "Your bio should be like a resume - an introduction to your band, and a description of your achievements. This is the place to show the full picture of your band for any newcomers looking to learn more about you. Industry professionals might also visit your bio page for details about your band."
								: "User has not updated their bio"}
						</Typography>
					))}

				{(!isAdmin &&
					viewer !== "SELF" &&
					permissions?.viewProfilePermission ===
						"No one can view profile. Only me") ||
				(viewer === "NOT_IN_CONNECTION" &&
					permissions?.viewProfilePermission ===
						"Only my connections can view full profile") ? (
					""
				) : (
					<>
						<Divider variant="middle" light sx={{ ml: 1, mr: 1 }} />
						<CardContent>
							<Tabs
								value={selectedTab}
								onChange={(event, value) => handleChange(value)}
								aria-label="Plain tabs"
								sx={{
									"& .Mui-selected": {
										background: "#0273E6",
										textDecoration: "none",
										color: "#ffffff !important",
										"&:active": {
											background: "#DAECFF !important",
											color: "#1D2D53 !important",
										},
									},
									"& .MuiTabs-indicator": {
										display: "none",
									},
									minHeight: "36px",
									maxHeight: "36px",
									borderRadius: "lg",
								}}
								// defaultValue='myActivities'
							>
								{!isAdmin && viewer === "SELF"
									? TabsMenuSelf.map((tabMenu) => (
											<Tab
												key={tabMenu.id}
												sx={{
													backgroundColor:
														selectedTab === tabMenu.id ? "#0273E6" : "#F5F5F5",
													// color: selectedTab === tabMenu.id ? 'white' : 'black',
													borderRadius: "20px",
													minHeight: "36px",
													maxHeight: "36px",
													mr: 2,
													fontSize: "16px",
													color: "#1D2D53",
													fontWeight: "600 !important",
													fontFamily: "Lexend",
													textTransform: "none",
													"&:active": {
														background: "#EEF6FF",
														color: "#1D2D53",
													},
												}}
												value={tabMenu.id}
												label={tabMenu.label}
											/>
									  ))
									: TabsMenu.map((tabMenu) => (
											<Tab
												key={tabMenu.id}
												sx={{
													backgroundColor:
														selectedTab === tabMenu.id ? "#0273E6" : "#F5F5F5",
													// color: selectedTab === tabMenu.id ? 'white' : 'black',
													borderRadius: "20px",
													minHeight: "36px",
													maxHeight: "36px",
													mr: 2,
													fontSize: "16px",
													color: "#1D2D53",
													fontWeight: 600,
													fontFamily: "Lexend",
													textTransform: "none",
													"&:active": {
														background: "#EEF6FF",
														color: "#1D2D53",
													},
												}}
												value={tabMenu.id}
												label={tabMenu.label}
											/>
									  ))}
							</Tabs>
						</CardContent>
					</>
				)}
			</Card>

			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="xs"
				onClose={() => setRemoveConfirmation(false)}
				open={removeConfirmation}
			>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							className="dialog-title-custom-size"
							sx={{
								fontWeight: 500,
								color: "#000000",
								fontSize: "24px !important",
							}}
						>
							{i18n.musicians.connection.removeConnectionTitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						xs={12}
						// columnSpacing={2}
					>
						<Typography
							className="dialog-title-custom-sizea"
							variant="h4"
							sx={{
								fontWeight: 500,
								color: "#000000",
								fontSize: "16px !important",
								textAlign: "center",
							}}
						>
							{i18n.musicians.connection.removeConfirmationTitle}
							{!isAdmin && viewer === "IN_CONNECTION" ? (
								<>
									&nbsp;
									{
										props.userDetails.privacySettings?.userProfile?.user
											?.firstName
									}{" "}
									{
										props.userDetails.privacySettings?.userProfile?.user
											?.lastName
									}
								</>
							) : (
								""
							)}
						</Typography>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Grid item={true}>
							<Button
								className="cancel-button"
								sx={{
									fontSize: "18px",
								}}
								size="medium"
								// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
								variant="outlined"
								onClick={() => setRemoveConfirmation(false)}
							>
								{i18n.dialog.cancel}
							</Button>
						</Grid>
						<Grid item={true}>
							<Button
								className="submit-button"
								size="small"
								variant="contained"
								// disabled={serialNumber === "" || purchaseDate === null || (purchaseDate !== null && purchaseDate >= new Date())}
								onClick={() => sendConnectionRequest(user?.id, "REMOVED")}
								// disabled={connnectionRequestNote.length > 255}
								// endIcon={<img src={RightArrow} alt="Down Arrow" />}
							>
								<Typography sx={{ marginRight: "0px !important " }}>
									{i18n.musicians.confirm}
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			<ShareProfile
				open={openShareProfileDialog}
				handleClose={handleShareProfileDialog}
				profileLink={profileLink}
				menuClose={() => setOpen(false)}
			/>
		</>
	);
};

export default BriefBio;
