import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	Typography,
	Divider,
	Grid,
	FormHelperText,
	TextField,
	Checkbox,
	FormControlLabel,
	Autocomplete,
	Button,
	ButtonGroup,
	Slider,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { styled, Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../../context/UserContext";
import i18n from "../../../l10n/strings.json";
import InstrumentSkillLevel from "../../Musicians/InstrumentSkills";

import ErrorAlert from "../../Error/ErrorAlert";

// import useMusiciansFilter from "../../hooks/useMusicians/useMusiciansFilter";
import { useUserManageFilter } from "./UserManagementProvider/useUserManageFilter";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
	LocalizationProvider,
	DatePicker,
	StaticDatePicker,
} from "@mui/x-date-pickers";
import GoogleLocationService from "../../GoogleLocationService";
import { useManageFilter } from "./ManagementProvider/useManageFilter";
import { error } from "console";

type RouteParams = {
	menuId: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			padding: "1% 17px",
			borderRadius: "15px",
			//boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
		backBtn: {
			width: "100%",
			fontWeight: "500 !important",
			color: "#0273E6 !important",
		},
		root: {
			color: "white",
			// '& .MuiAutocomplete-tag': {
			//     backgroundColor: 'blue',
			//     color: 'white'
			// },
			"& .MuiSvgIcon-root": {
				color: "white",
			},
			"& .MuiAutocomplete-endAdornment": {
				"& .MuiSvgIcon-root": {
					color: "#000 !important",
				},
			},
			"& .MuiChip-root.MuiChip-filled": {
				backgroundColor: "#0273E6",
				fontFamily: "Lexend",
				fontWeight: "400",
				color: "white",
				"& svg.MuiChip-deleteIcon": {
					color: "white !important",
				},
				"& span.MuiAutocomplete-tag ": {
					color: "black !important",
					backgroundColor: "white !important",
				},
			},
		},
	})
);

type Genres = {
	// id: string;
	title: string;
};

type Instrument = {
	id: string;
	instrumentName: string;
	icon?: string;
};

const genreFilter: Array<Genres> = [
	{ title: "Americana" },
	{ title: "Avant-garde" },
	{ title: "Baroque" },
	{ title: "Big Band" },
	{ title: "Bluegrass" },
	{ title: "Blues" },
	{ title: "Bollywood" },
	{ title: "Broadway/Showtunes" },
	{ title: "Chamber Music" },
	{ title: "Classic Rock" },
	{ title: "Classical" },
	{ title: "Contemporary Classical" },
	{ title: "Country" },
	{ title: "Disco" },
	{ title: "Drumline" },
	{ title: "EDM" },
	{ title: "Electronic/Dance" },
	{ title: "Folk" },
	{ title: "Funk" },
	{ title: "Gospel" },
	{ title: "Hip-hop/Rap" },
	{ title: "House" },
	{ title: "Indie/Alternative" },
	{ title: "Jazz" },
	{ title: "K-Pop" },
	{ title: "Latin" },
	{ title: "Metal" },
	{ title: "Minimalism" },
	{ title: "New Age" },
	{ title: "Opera" },
	{ title: "Orchestral" },
	{ title: "Pop" },
	{ title: "Punk" },
	{ title: "R&B" },
	{ title: "Ragtime" },
	{ title: "Reggae" },
	{ title: "Reggaeton" },
	{ title: "Religious" },
	{ title: "Rock" },
	{ title: "Romantic" },
	{ title: "Salsa" },
	{ title: "Ska" },
	{ title: "Soul" },
	{ title: "Soundtrack" },
	{ title: "Swing" },
	{ title: "Symphonic" },
	{ title: "Techno" },
	{ title: "Wind Ensemble" },
];

const marks = [
	{
		value: 0,
		label: 25,
	},
	{
		value: 25,
		label: 50,
	},
	{
		value: 50,
		label: 100,
	},
	{
		value: 75,
		label: 500,
	},
	{
		value: 100,
		label: "Any",
	},
];

const options = [
	"30 Days",
	"60 Days",
	"90 Days",
	"6 Months",
	"1 Year",
	"Custom Range",
];
const CustomButton = styled(Button)(({ theme }) => ({
	backgroundColor: "#FF5733",
	color: "#fff",
	"&:hover": {
		backgroundColor: "#C70039",
	},
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
	"&.MuiTextField-root": {
		backgroundColor: "#f0f0f0",
		width: "100%",
	},
	input: {
		color: "#000",
		fontSize: "1rem",
		fontWeight: 500,
	},
}));

const Filter = () => {
	const param = useParams<RouteParams>();
	const classes = useStyles();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	
	const {
		resetManagementFilter,
		managementFilter,
		setManagementFilter,
		setFilterFor,
		setUserStatus1,
		setSubscriptionStatus1,
		setOrderByField,
		orderByField,
		handleFilterReset,
		selectedButton,
		setSelectedButton,
		isFilterActive,
		setIsFilterActive,
	} = useManageFilter();
	const [pageTitle, setPageTitle] = useState("");
	const [genres, setGenres] = useState("");
	const [instrumentList, setInstrumentList] = useState<Array<Instrument>>([]);
	const [selectedInstrumentFilter, setSelectedInstrumentsFilter] = useState<
		Array<Instrument> | []
	>([]);
	const theme = useTheme();
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [joinDate, setJoinDate] = useState<Date | null>(null);
	const updateSelectedInstruments = (newInstrumentSet: any) => {
		setSelectedInstrumentsFilter(newInstrumentSet);
	};
	const [selectedDeviceStatus, setSelectedDeviceStatus] = useState("");
	const [deviceVersion, setDeviceVersion] = useState<Array<string>>([]);
	const [versionOptions, setVersionOptions] = useState<Array<string>>([]);
	const [selectedDeviceVersion, setSelectedDeviceVersion] = useState("");
	const [selectedSoftwareVersion, setSelectedSoftwareVersion] = useState("");
	const [selectedGenres, setSelectedGenres] = useState([]);
	// const [lastSeen, setLastSeen] = useState<Date | null>(null);
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [openForCollab, setOpenForCollab] = useState(null);
	const [openForGigs, setOpenForGigs] = useState(null);
	const [firstName, setFirstName] = useState("");
	const [email, setEmail] = useState("")
	const [city, setCity] = useState("");
	const [lat, setLat] = useState();
	const [long, setLong] = useState();
	const [displayDistance, setDisplayDistance] = useState(25);
	const [instruments, setInstruments] = useState([]);
	const [applyFilter, setApplyFilter] = useState<boolean>(true);
	const [resetFilter, setResetFilter] = useState<boolean>(true);

	const [open, setOpen] = useState(false);
	const [dateRange, setDateRange] = useState([null, null]);
	const [selectedOption, setSelectedOption] = useState<string | null>(null);

	useEffect(() => {
		getVersions();
	}, []);

	const updateSearchFilter = (field: string, value: any) => {
		setApplyFilter((prevState: boolean) => {
			if (prevState) {
				return false;
			} else {
				return false;
			}
		});
		setIsFilterActive((prevState: boolean) => {
			if (prevState) {
				return true;
			} else {
				return true;
			}
		});
		switch (field) {
			case "name":
				setManagementFilter({
					...managementFilter,
					firstName: value,
				});
				console.log("First name", value);
				break;
				case "email":
					setManagementFilter({
						...managementFilter,
						isGlobalSearch: false,
						email: value,
					});
					console.log("email", email);
					break;
			case "genres":
				var selectedGenres: any = [];
				value.map((genre: any) => {
					selectedGenres.push(genre.title);
				});
				setManagementFilter({
					...managementFilter,
					isGlobalSearch: false,
					genres: selectedGenres,
				});
				break;
			case "instruments":
				var selectedInstrument: any = [];
				value.map((inst: any) => {
					selectedInstrument.push(inst.instrumentName);
				});
				setManagementFilter({
					...managementFilter,
					isGlobalSearch: false,
					instrument: selectedInstrument,
				});
				break;
			case "collaboration":
				setManagementFilter({
					...managementFilter,
					isGlobalSearch: false,
					isOpenForCollaboration: value,
				});
				break;
			case "location":
				setManagementFilter({
					...managementFilter,
					cities: value,
					isGlobalSearch: false,
				});
				getLocationLatLng(value).then((result: any) => {
					setManagementFilter({
						...managementFilter,
						latitude: result.lat,
						longitude: result.lng,
						isGlobalSearch: false,
					});
				});

				break;
			case "acceptGigs":
				setManagementFilter({
					...managementFilter,
					acceptGigs: value,
					isGlobalSearch: false,
				});
				break;
			case "joinDate":
				setManagementFilter((prevFilter: any) => ({
					...prevFilter,
					joinDate: value,
					isGlobalSearch: false,
				}));
				break;
			case "startDate":
				setManagementFilter((prevFilter: any) => ({
					...prevFilter,
					startDate: value,
					isGlobalSearch: false,
				}));
			
				break;
			case "endDate":
				setManagementFilter((prevFilter: any) => ({
					...prevFilter,
					endDate: value,
					isGlobalSearch: false,
				}));
				break;

			case "softwareVersion":
				
				setManagementFilter({
					...managementFilter,
					softwareVersion: value,
					IsGlobalSearch: false,
				});
				break;

			case "deviceVersion":
				setManagementFilter({
					...managementFilter,
					softwareVersion: value,
					IsGlobalSearch: false,
				});
				break;

			case "deviceStatus":
				setManagementFilter({
					...managementFilter,
					devicestatus: value,
					isGlobalSearch: false,
				});
				break;

			case "displayDistance":
				setManagementFilter({
					...managementFilter,
					distanceFromCity: value,
					isGlobalSearch: false,
				});
				break;

			default:
				break;
		}
	};

	const getVersions = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-device/software-device-version`,
			{
				method: "GET",
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((response: any) => {
				if (response.status >= 400) {
					console.log("Error");
				} else {
					return response.json();
				}
			})
			.then((result: any) => {
				setDeviceVersion(result.deviceVersion);
				setVersionOptions(result.softwareVersion);
				console.log(result);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleApplyFilter = () => {
		setApplyFilter(true);
		setResetFilter(false);
		handleFilterReset();
	};

	const handleFilterResetIn = () => {
		setIsFilterActive((prev: boolean) => {
			if (prev) {
				return false;
			} else {
				return false;
			}
		});
		resetManagementFilter();
		setApplyFilter(true);
		setResetFilter(true);
		setInstruments([]);
		setCity("");
		setStartDate(null);
		setEndDate(null);
		setSelectedOption(null);
		setSelectedGenres([]);
		setOpenForCollab(null);
		setOpenForGigs(null);
		setFirstName("");
		setEmail("");
		setSelectedDeviceStatus("");
		setSelectedSoftwareVersion("");
		setSelectedDeviceStatus("");
		handleFilterReset(undefined, "reset");
	};

	useEffect(() => {
		getInstrumentsList();
	}, []);

	const getInstrumentsList = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setInstrumentList(data);
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	function valuetext(value: number) {
		return `${value}`;
	}

	const handleClick = () => {
		if (selectedButton === "user") {
			setSelectedButton("device");
			// setFilterFor("Device");
			setUserStatus1(null);
			setSubscriptionStatus1(null);
			setOrderByField("");
			handleFilterResetIn();
		} else {
			setSelectedButton("user");
			// setFilterFor("User");
			setUserStatus1(null);
			setSubscriptionStatus1(null);
			setOrderByField("");
			handleFilterResetIn();
		}
	};

	function getLocationLatLng(
		location: string
	): Promise<{ lat: number; lng: number }> {
		return fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
				location
			)}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(
						`Geocoding API request failed with status: ${response.status}`
					);
				}
			})
			.then((data) => {
				if (data.status === "OK") {
					const { lat, lng } = data.results[0].geometry.location;
					const result = { lat, lng };
					setLat(result.lat);
					setLong(result.lng);
					return result;
				} else {
					throw new Error("Geocoding API request failed");
				}
			})
			.catch((error) => {
				console.error("Error during geocoding request:", error);
				throw error;
			});
	}

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setSelectedOption(` `);
		setOpen(false);
	};

	const handleApply = () => {
		// setSelectedOption(` `);
		setOpen(false);
	};


	const handleDateRangeChange = (option: string | null) => {
        if (!option) return;
		setSelectedOption(option)
		const today = new Date();
		let newStartDate = new Date(today);
		let newEndDate = new Date(today);

        switch (option) {
            case "30 Days":
                newStartDate.setDate(today.getDate() - 30);
                break;
            case "60 Days":
                newStartDate.setDate(today.getDate() - 60);
                break;
            case "90 Days":
                newStartDate.setDate(today.getDate() - 90);
                break;
            case "6 Months":
                newStartDate.setMonth(today.getMonth() - 6);
                break;
            case "1 Year":
                newStartDate.setFullYear(today.getFullYear() - 1);
                break;
            case "Custom Range":
                handleOpen();

                return;
            default:
                return;
        }

		// Convert the dates to UTC
		const utcStartDate = new Date(
			Date.UTC(
				newStartDate.getFullYear(),
				newStartDate.getMonth(),
				newStartDate.getDate()
			)
		);
		const utcEndDate = new Date(
			Date.UTC(
				newEndDate.getFullYear(),
				newEndDate.getMonth(),
				newEndDate.getDate()
			)
		);
		setStartDate(utcStartDate);
		setEndDate(utcEndDate);
		updateSearchFilter("startDate", utcStartDate.toISOString());
		updateSearchFilter("endDate", utcEndDate.toISOString());
	};

	return (
		<>
			<Grid
				container
				xs={12}
				sx={{ height: "fit-content", width: "288px" }}
				className={`${classes.shadowedBox}`}
			>
				{/* Name */}
				<Grid item xs={12}>
					<Typography
						variant="h5"
						sx={{
							textAlign: "center",
							fontWeight: "500 !important",
							fontSize: "24px !important",
						}}
					>
						Filters
					</Typography>
				</Grid>
				{/* ButtonGroup */}
				<Grid item xs={12} sx={{ marginTop: "10px" }}>
					<ButtonGroup
						sx={{
							display: " flex",
							justifyContent: "center",
							width: "241px",
							height: "31px",
							borderRadius: "17px",
							// border: "1px",
						}}
					>
						<Button
							variant={selectedButton === "user" ? "contained" : "outlined"}
							id="user"
							sx={{
								width: "50%",
								backgroundColor: selectedButton === "user" ? "#0273e6" : "#fff",
								color: selectedButton === "user" ? "#fff" : "#0273e6",
								"&:hover": {
									backgroundColor:
										selectedButton === "user" ? "#0273e6" : "#fff",
								},
								border: "1px solid #0273e6 !important",
							}}
							onClick={handleClick}
						>
							User
						</Button>
						<Button
							variant={selectedButton === "device" ? "contained" : "outlined"}
							id="device"
							sx={{
								width: "50%",
								backgroundColor:
									selectedButton === "device" ? "#0273e6" : "#fff",
								color: selectedButton === "device" ? "#fff" : "#0273e6",
								"&:hover": {
									backgroundColor:
										selectedButton === "device" ? "#0273e6" : "#fff",
								},
								border: "1px solid #0273e6 !important",
							}}
							onClick={handleClick}
						>
							Device
						</Button>
					</ButtonGroup>
				</Grid>
				{selectedButton === "user" ? (
					<>
						{/* Username */}
						<Grid item xs={12} sx={{ pr: "10px" }}>
							<FormHelperText className="jamsession-label">Name</FormHelperText>
							<TextField
								inputProps={{
									style: {
										background: "#F8F8F8",

										fontStyle: "normal",
										fontSize: "1rem",
										lineHeight: "25px",
										fontWeight: 900,
										color: "#000000",
										padding: "15px",
									},
								}}
								fullWidth
								variant="outlined"
								value={firstName}
								onChange={(event) => {
									setFirstName(event.target.value);
									updateSearchFilter("name", event.target.value);
								}}
								error={firstName?.length > 64}
								size="small"
							></TextField>
							<FormHelperText
								sx={{
									fontSize: "0.8125rem",
									color: "#FD4B24",
									textAlign: "right",
								}}
							>
								{firstName?.length > 64 ? "Enter maximum 64 characters" : ""}
							</FormHelperText>
						</Grid>
						<Grid item xs={12} sx={{ pr: "10px" }}>
							<FormHelperText className="jamsession-label">Email</FormHelperText>
							<TextField
								inputProps={{
									style: {
										background: "#F8F8F8",

										fontStyle: "normal",
										fontSize: "1rem",
										lineHeight: "25px",
										fontWeight: 900,
										color: "#000000",
										padding: "15px",
									},
								}}
								fullWidth
								variant="outlined"
								value={email}
								onChange={(event) => {
									setEmail(event.target.value);
									updateSearchFilter("email", event.target.value);
								}}
								error={email?.length > 64}
								size="small"
							></TextField>
							<FormHelperText
								sx={{
									fontSize: "0.8125rem",
									color: "#FD4B24",
									textAlign: "right",
								}}
							>
								{email?.length > 64 ? "Enter maximum 64 characters" : ""}
							</FormHelperText>
						</Grid>
						{/* Genres */}
						{/* <Grid item xs={12}>
							<FormHelperText className="jamsession-label">
								{i18n.usersmanagement.filters.genresTitle}ddd
							</FormHelperText>
							<Autocomplete
								multiple
								limitTags={1}
								id="multiple-limit-tags"
								options={genreFilter}
								getOptionLabel={(option) => option.title}
								classes={classes}
								getOptionDisabled={(option: any) =>
									!!selectedInstrumentFilter.find(
										(element) => element.id === option.id
									)
								}
								// defaultValue={}
								value={genreFilter.filter((genre: any) => {
									return managementFilter.genres.includes(genre.title as never);
								})}
								renderInput={(params) => <TextField {...params} />}
								sx={{ width: "100%" }}
								disableClearable={false}
								onChange={(event, newGenres) =>
									updateSearchFilter("genres", newGenres)
								}
							/>
							<FormHelperText
								sx={{
									fontSize: "0.8125rem",
									color: "#FD4B24",
									textAlign: "right",
								}}
							>
								{genres?.length > 64 ? "Enter maximum 64 characters" : ""}
							</FormHelperText>
						</Grid> */}
						{/* Join Date */}
						{/* <Grid item xs={12}>
							<FormHelperText className="jamsession-label">
								<Typography variant="body4" color="customColor.label">
									Join Date
								</Typography>
							</FormHelperText>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									openTo="day"
									views={["year", "month", "day"]}
									inputFormat="MM/dd/yyyy"
									value={startDate}
									onChange={(newValue: Date | null) => {
										if (newValue) {
											const utcDate = new Date(
												Date.UTC(
													newValue.getFullYear(),
													newValue.getMonth(),
													newValue.getDate()
												)
											);

											setStartDate(utcDate);
											updateSearchFilter("joinDate", utcDate);
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											onKeyDown={(e) => e.preventDefault()}
											sx={{
												"&.MuiTextField-root": {
													backgroundColor: "customColor.gray1",
													width: "100%",
												},
												input: {
													color: "customColor.black",
													fontSize: theme.typography.subtitle1.fontSize,
													fontWeight: theme.typography.subtitle1.fontWeight,
												},
											}}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid> */}
						{/* City */}
						{/* <Grid item xs={12} sx={{ pr: "10px" }}>
							<FormHelperText className="jamsession-label">
								{i18n.musicians.filters.cityTitle}
							</FormHelperText>
							<GoogleLocationService
								onLocationChange={(locationDesc: string) => {
									if (locationDesc !== "") {
										updateSearchFilter("location", locationDesc);
									}
									setCity(locationDesc);
								}}
								defaultValue={city}
							/>
							<FormHelperText />
						</Grid> */}
						{/* Distance */}
						{/* <Grid item xs={12} sx={{ pr: "10px" }}>
							<FormHelperText className="jamsession-label">
								{i18n.musicians.filters.distanceTitle}
							</FormHelperText>
							{/* <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" /> */}
							{/* <Slider
								defaultValue={25}
								disabled={city?.length === 0}
								value={displayDistance}
								step={null}
								marks={marks}
								onChange={(event, value) => {
									// Handle the value change
									let updatedValue: any;
									marks.map((mark) => {
										if (mark.value === value) {
											if (mark.value === 100) {
												updatedValue = 100000;
												setDisplayDistance(100);
												updateSearchFilter("displayDistance", 100);
											} else {
												updatedValue = mark.label;
												setDisplayDistance(mark.value);
												updateSearchFilter("displayDistance", mark.value);
											}
										}
									});
									// setTest(value);
								}}
								valueLabelFormat={valuetext}
							/>
							<FormHelperText
								sx={{
									fontSize: "0.8125rem",
									color: "#FD4B24",
									textAlign: "right",
								}}
							>
								{genres?.length > 64 ? "Enter maximum 64 characters" : ""}
							</FormHelperText> */}
						{/* </Grid>  */}
						{/* Instruments */}
						{/* <Grid item xs={12}>
							<FormHelperText className="jamsession-label">
								{i18n.usersmanagement.filters.instrumentsTitle}
							</FormHelperText>
							<Autocomplete
								multiple
								limitTags={1}
								id="multiple-limit-tags1"
								options={instrumentList}
								getOptionLabel={(option) => option.instrumentName}
								classes={classes}
								// getOptionDisabled={(option) => !!selectedInstrumentList.find(element => element.id === option.id)}
								// defaultValue={}
								value={instrumentList.filter((ins: any) => {
									return managementFilter.instrument.includes(
										ins.instrumentName as never
									);
								})}
								renderInput={(params) => <TextField {...params} />}
								sx={{ width: "100%" }}
								onChange={(event, newInstrument) =>
									updateSearchFilter("instruments", newInstrument)
								}
							/>
						</Grid> */}

						{/* Instruments Music Notes */}
						{/* <Grid item xs={12} marginY={1}>
							<Grid item>
								{selectedInstrumentFilter.map((instrument) => {
									return (
										<InstrumentSkillLevel
											instrument={instrument.id}
											skillLevel={0}
											editable={true}
										/>
									);
								})}
							</Grid>
						</Grid>
						<Grid item xs={12} sx={{ pr: "10px" }}>
							<Divider />
						</Grid> */}
						{/* Collaboration and  */}
						{/* <Grid item xs={12} sx={{ pr: "10px" }}>
							<FormControlLabel
								control={
									<Checkbox
										onChange={(event) =>
											updateSearchFilter("collaboration", event.target.checked)
										}
										sx={{ "&.Mui-checked": { color: "#0273e6" } }}
									/>
								}
								label="Open for Collaboration"
								checked={managementFilter.isOpenForCollaboration}
							/>
						</Grid> */}
						{/* Gig */}
						{/* <Grid item xs={12} sx={{ pr: "10px" }}>
							<FormControlLabel
								control={
									<Checkbox
										onChange={(event) =>
											updateSearchFilter("acceptGigs", event.target.checked)
										}
										sx={{ "&.Mui-checked": { color: "#0273e6" } }}
									/>
								}
								label="Accept Gigs"
								checked={managementFilter.acceptGigs}
							/>
						</Grid>
						<Grid item xs={12} sx={{ pr: "10px" }}>
							<Divider />
						</Grid> */}
					</>
				) : (
					// Device filter
					<>
						{/* Last Online */}
						<Grid item xs={12}>
							<FormHelperText className="jamsession-label">
								<Typography variant="body4" color="customColor.label">
									Last Connected
								</Typography>
							</FormHelperText>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<Autocomplete
									options={options}
									sx={{ width: "100%" }}
									disableClearable={false}
									value={selectedOption}
									onChange={(event, value) => {
										if (!value) {
										  setStartDate(null);
										  setEndDate(null);
										}
										handleDateRangeChange(value);
									  }}
									renderInput={(params) => (
										<TextField {...params} variant="outlined" />
									)}
								/>
								<Dialog
									open={open}
									onClose={handleClose}
									fullWidth
									maxWidth="sm"
								>
									<DialogTitle>Date Range</DialogTitle>
									<DialogContent>
										<Grid container spacing={2} justifyContent="center">
											<Grid item xs={12} sm={6}>
												<Grid container justifyContent="flex-start" alignItems="center">
														<Typography variant="subtitle1" gutterBottom>
															From: 
														</Typography>
														<Typography variant="body1" gutterBottom sx={{ marginLeft: '4px' }}>
															{startDate ? startDate.toLocaleDateString('en-GB', {
																day: '2-digit',
																month: 'short',
																year: '2-digit',
															}) : 'Not Selected'}
														</Typography>
													</Grid>
												<DatePicker
													openTo="day"
													views={["year", "month", "day"]}
													value={startDate}
													onChange={(newValue) => {
														if (newValue) {
															const utcDate = new Date(
																Date.UTC(
																	newValue.getFullYear(),
																	newValue.getMonth(),
																	newValue.getDate()
																)
															);
															setStartDate(utcDate);
															
															const formattedStartDate = utcDate.toLocaleDateString('en-GB', {
																day: '2-digit',
																month: 'short',
																year: '2-digit',
															});
															const formattedEndDate = endDate?.toLocaleDateString('en-GB', {
																day: '2-digit',
																month: 'short',
																year: '2-digit',
															});
															setSelectedOption(`${formattedStartDate} - ${formattedEndDate}`);
															updateSearchFilter("startDate", utcDate.toISOString());
														}
													}}
													maxDate={new Date()} 
													renderInput={(params) => (
														<TextField
															{...params}
															onKeyDown={(e) => e.preventDefault()}
															sx={{
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																	width: "100%",
																},
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight:
																		theme.typography.subtitle1.fontWeight,
																},
															}}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Grid container justifyContent="flex-start" alignItems="center">
														<Typography variant="subtitle1" gutterBottom>
															To: 
														</Typography>
														<Typography variant="body1" gutterBottom sx={{ marginLeft: '4px' }}>
															{endDate ? endDate.toLocaleDateString('en-GB', {
																day: '2-digit',
																month: 'short',
																year: '2-digit',
															}) : 'Not Selected'}
														</Typography>
												</Grid>
												<DatePicker
													openTo="day"
													views={["year", "month", "day"]}
													value={endDate}
													onChange={(newValue) => {
														if (newValue) {
															const utcDate = new Date(
																Date.UTC(
																	newValue.getFullYear(),
																	newValue.getMonth(),
																	newValue.getDate()
																)
															);
															setEndDate(utcDate);
															const formattedStartDate = startDate?.toLocaleDateString('en-GB', {
																day: '2-digit',
																month: 'short',
																year: '2-digit',
															});
															
															const formattedEndDate = utcDate.toLocaleDateString('en-GB', {
																day: '2-digit',
																month: 'short',
																year: '2-digit',
															});
															
															setSelectedOption(`${formattedStartDate} - ${formattedEndDate}`);
															updateSearchFilter("endDate", utcDate.toISOString());
														}
													}}
													maxDate={new Date()} 
													renderInput={(params) => (
														<TextField
															{...params}
															onKeyDown={(e) => e.preventDefault()}
															sx={{
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																	width: "100%",
																},
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight:
																		theme.typography.subtitle1.fontWeight,
																},
															}}
														/>
													)}
												/>
											</Grid>
										</Grid>
									</DialogContent>
									<DialogActions>
										<Button 
										sx={{
											width: "140px",
											fontSize: "18px !important",
											marginTop: "10px",
										}}
										fullWidth
										variant="outlined"
										onClick={handleClose}>
										Cancel
										</Button>
										<Button
										sx={{
											width: "140px",
											fontSize: "18px !important",
											marginTop: "10px",
										}}
										fullWidth
										variant="contained"
										onClick={handleApply}
										disabled={!startDate || !endDate}
										>
										Apply	
										</Button>
									</DialogActions>
								</Dialog>
							</LocalizationProvider>
						</Grid>

						{/* Device Status */}
						<Grid item xs={12}>
							<FormHelperText className="jamsession-label">
								Device Status
							</FormHelperText>
							<Autocomplete
								limitTags={1}
								freeSolo
								id="multiple-limit-tags"
								options={["Offline", "Online"]}
								getOptionLabel={(option) => option}
								classes={classes}
								defaultValue={"Online"} // Set the default value to "Online"
								value={selectedDeviceStatus} // Control the value with selectedDeviceStatus state
								renderInput={(params) => <TextField {...params} />}
								sx={{ width: "100%" }}
								disableClearable={false}
								onChange={(event, newStatus: any) => {
									setSelectedDeviceStatus(newStatus);
									updateSearchFilter("deviceStatus", newStatus);
								}}
							/>
							<FormHelperText
								sx={{
									fontSize: "0.8125rem",
									color: "#FD4B24",
									textAlign: "right",
								}}
							>
								{/* Additional validation message here if needed */}
							</FormHelperText>
						</Grid>

						{/* Device version */}
						{/* <Grid item xs={12}>
							<FormHelperText className="jamsession-label">
								Device version
							</FormHelperText>
							<Autocomplete
								freeSolo
								limitTags={1}
								id="multiple-limit-tags"
								options={deviceVersion}
								getOptionLabel={(option) => option}
								classes={classes}
								// getOptionDisabled={(option) => !!selectedInstrumentFilter.find(element => element.id === option.id)}
								// defaultValue={""}
								value={selectedDeviceVersion}
								renderInput={(params) => <TextField {...params} />}
								sx={{ width: "100%" }}
								disableClearable={false}
								onChange={(event, newStatus: any) => {
									setSelectedDeviceStatus(newStatus);
									updateSearchFilter("deviceVersion", selectedDeviceVersion);
								}}
							/>
							<FormHelperText
								sx={{
									fontSize: "0.8125rem",
									color: "#FD4B24",
									textAlign: "right",
								}}
							>
								{genres?.length > 64 ? "Enter maximum 64 characters" : ""}
							</FormHelperText>
						</Grid> */}
						{/* Software version */}
						<Grid item xs={12}>
							<FormHelperText className="jamsession-label">
								Software version
							</FormHelperText>
							<Autocomplete
								freeSolo
								limitTags={1}
								id="multiple-limit-tags"
								options={versionOptions}
								getOptionLabel={(option) => option}
								classes={classes}
								// getOptionDisabled={(option) => !!selectedInstrumentFilter.find(element => element.id === option.id)}
								// defaultValue={}
								// value={genreFilter.filter((genre: any) => {
								// 	return userManagementFilter.genres.includes(
								// 		genre.title as never
								// 	);
								// })}
								value={selectedSoftwareVersion}
								renderInput={(params) => <TextField {...params} />}
								sx={{ width: "100%" }}
								disableClearable={false}
								onChange={(event, newStatus: any) => {
									setSelectedSoftwareVersion(newStatus);
									updateSearchFilter("softwareVersion", newStatus);
								}}
							/>
						</Grid>
						<Grid item xs={12} sx={{ pr: "10px" }}>
							<Divider />
						</Grid>
					</>
				)}
				<Grid item xs={12}>
					<Button
						size="large"
						sx={{
							width: "100%",
							fontSize: "18px !important",
							marginTop: "10px",
						}}
						fullWidth
						variant="contained"
						onClick={handleApplyFilter}
						disabled={applyFilter}
					>
						Apply Filter
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button
						size="large"
						sx={{
							width: "100%",
							fontSize: "18px !important",
							marginTop: "10px",
						}}
						fullWidth
						variant="outlined"
						onClick={handleFilterResetIn}
						disabled={resetFilter}
					>
						Reset All
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default Filter;
