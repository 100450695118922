import { memo, useState, useEffect, useRef } from "react";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import {
	Box,
	AppBar,
	Toolbar,
	Select,
	Menu,
	MenuItem,
	IconButton,
	Typography,
	Divider,
	ListItemText,
	ListItemButton,
	ListItemIcon,
	Popper,
	Snackbar,
	Tooltip,
	Badge,
} from "@mui/material";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import AudioControl from "./AudioControl";
import ToggleChatButton from "../Buttons/ToggleChatButton/ToggleChatButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import Mixer from "../Mixer/Mixer";
import SessionBroadcastDialog from "../../dialogBox/SessionBroadcastDialog";

import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import { useUser } from "../../context/UserContext";
import usePeopleContext from "../../hooks/usePeopleContext/usePeopleContext";
import { useFullscreenModeContext } from "../../context/FullscreenModeContext";

import Smileys from "../../images/smileys.svg";
import People from "../../images/people.svg";
import Recording from "../../images/record.svg";
import ActiveRecording from "../../images/active-record.svg";
import MixerIcon from "../../images/candle.svg";
import ActiveMixer from "../../images/active-mixer.svg";
import RehearsalStudio from "../../images/hard-rock.svg";
import JazzClub from "../../images/vocal.svg";
import Theatre from "../../images/bass.svg";
import None from "../../images/none.svg";
import PerformanceHall from "../../images/techno.svg";
import CloseIcon from "../../images/none.svg";
import StartFullscreen from "../../images/maximize.svg";
import StopFullscreen from "../../images/stop-fullscreen.svg";
import SessionMenuIcon from "../../images/menu-dots.svg";
import LiveStreaming from "../../images/live-streaming.svg";
import EndLiveStreaming from "../../images/stop-broadcasting.svg";
import ChangeLayout from "../../images/change-layout.svg";
import HideTab from "../../images/hide-tab.svg";
import ShowTab from "../../images/show-tab.svg";
import SessionInfo from "../../images/session-info.svg";
import { Constants } from "../../services/constants";
// import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import i18n from "../../l10n/strings.json";
import { useBroadcastingStatusContext } from "../../context/BroadcastingStatusContext";
import { SessionInterface } from "../../types/types";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import SessionInformation from "../../dialogBox/SessionInformation";
import useSnackbarAlert from "../../hooks/useSnackbarAlert/useSnackbarAlert";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			color: "#C5C5C5 !important",
			backgroundColor: "#313131 !important",
			borderRadius: "6px !important",
			position: "sticky",
			display: "flex",
			padding: "0px",
			zIndex: 10,
			[theme.breakpoints.down("sm")]: {
				height: `${theme.mobileFooterHeight}px`,
				padding: 0,
			},
		},
		screenShareBanner: {
			position: "fixed",
			zIndex: 8,
			bottom: `${theme.footerHeight}px`,
			left: 0,
			right: 0,
			height: "104px",
			background: "rgba(0, 0, 0, 0.5)",
			"& h6": {
				color: "white",
			},
			"& button": {
				background: "white",
				color: theme.brand,
				border: `2px solid ${theme.brand}`,
				margin: "0 2em",
				"&:hover": {
					color: "#600101",
					border: `2px solid #600101`,
					background: "#FFE9E7",
				},
			},
		},
		hideMobile: {
			display: "initial",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
	})
);

const audioEffects = [
	{ label: "None", value: "none", icon: None },
	{
		label: "Rehearsal Studio",
		value: "rehearsal",
		icon: RehearsalStudio,
	},
	{ label: "Jazz Club", value: "jazz", icon: JazzClub },
	{ label: "Theatre ", value: "reverb_2", icon: Theatre },
	{
		label: "Performance Hall",
		value: "performance_space",
		icon: PerformanceHall,
	},
];

const SessionMenu = [
	{
		label: "Live Streaming",
		id: "liveStreaming",
		icon: EndLiveStreaming,
	},
	{
		label: "Change Layout",
		id: "changeLayout",
		icon: ChangeLayout,
	},
	{
		label: "Hide tab bar",
		id: "hideTab",
		icon: HideTab,
	},
	{
		label: "Session info",
		id: "sessionInfo",
		icon: SessionInfo,
	},
];

const MenuBar = (props: any) => {
	const user = useUser();
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const _userSession = localStorage.getItem("userSession") ?? "";
	const token = localStorage.getItem("sessionId");
	const userSession = JSON.parse(_userSession);
	const isMixerOpen: boolean =
		localStorage.getItem("isMixerOpen") === "true" ? true : false;
	let _isRecordingStarted: boolean =
		localStorage.getItem("recordingStatus") === "Started" ? true : false;

	let _isStorageAvaiable: boolean =
		localStorage.getItem("storageUsage") !==
		localStorage.getItem("storageCapacity")
			? true
			: false;
	let _recordingInitiator: boolean =
		localStorage.getItem("recordingInitiator") === user.email ? true : false;
	let _audioEffect = localStorage.getItem("sessionAudioEffect");

	const classes = useStyles();

	const { isSharingScreen } = useVideoContext();
	const roomState = useRoomState();
	const isReconnecting = roomState === "reconnecting";
	const { room } = useVideoContext();
	const { isPeopleWindowOpen, setIsPeopleWindowOpen } = usePeopleContext();
	const { isChatWindowOpen, setIsChatWindowOpen } = useChatContext();
	const { isFullscreenMode, setIsFullscreenMode, isTabHidden, setIsTabHidden } =
		useFullscreenModeContext();
	const { isBroadcastingStarted, setIsBroadcastingStarted } =
		useBroadcastingStatusContext();
	const [audioEffect, setAudioEffect] = useState(
		_audioEffect ?? audioEffects[0].value
	);
	const [timer, setTimer] = useState(0);
	const [recordingTimer, setRecordingTimer] = useState<any>("");
	const [mixerOpen, setMixerOpen] = useState(isMixerOpen ?? false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isRecordingStarted, setIsRecordingStarted] =
		useState(_isRecordingStarted);
	const [open, setOpen] = useState(false);
	const [recordingInitiator, setRecordingInitiator] =
		useState(_recordingInitiator);
	const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
	const [broadcastDialogOpen, setBroadcastDialogOpen] = useState(false);
	const [sessionInformationDialogBoxOpen, setSessionInformationDialogBoxOpen] =
		useState(false);
	const recordingIntervalRef = useRef<any | undefined>(undefined);
	const [disabledRecordButton, setDisabledRecordButton] = useState(false);
	const [isLiveStreaming, setIsLiveStreaming] = useState(false);
	const { SnackbarAlert, handleAlertOpen } = useSnackbarAlert();
	const [broadcastResponse, setBroadcastResponse] = useState(null);
	const [streamStartedBy, setstreamStartedBy] = useState(null);
	const [isDisable, setIsDisable] = useState(false);
	const [recordingDisable, setIsRecordingDisable] = useState(false);

	useEffect(() => {
		WebsocketClientUSerStatus.on("recordingControl", onMessageReceived);
		WebsocketClientUSerStatus.on("audioEffect", onMessageReceivedAudioEffect);
		WebsocketClientUSerStatus.on(
			"sessionBroadcasting",
			onMessageReceivedBroadcasting
		);
		const startDate = new Date(props.startTime) ?? new Date();

		setInterval(() => {
			var timeDiff = Date.now() - startDate.getTime();
			setTimer(timeDiff);
		}, 1000);

		return () => {
			WebsocketClientUSerStatus.off("recordingControl", onMessageReceived);
			WebsocketClientUSerStatus.off(
				"audioEffect",
				onMessageReceivedAudioEffect
			);
			globalThis.clearInterval(recordingIntervalRef.current);
		};
	}, [props.startTime]);

	useEffect(() => {}, [isBroadcastingStarted]);

	useEffect(() => {
		if (
			(userSession.recordingStatus || _isRecordingStarted) &&
			userSession.recordingStartTime
		) {
			setIsRecordingStarted(true);
			if(!recordingInitiator){
				setIsRecordingDisable(true);
			}
			const sTime =
				new Date(userSession.recordingStartTime.toString()) ?? new Date();
			startRecordingTimer(sTime);
		}
	}, [isFullscreenMode]);

	const getTimePartsFromDuration = (duration: number) => {
		const ms = duration % 1000;
		const s = (duration - ms) / 1000;
		const seconds = s % 60;
		const m = (s - seconds) / 60;
		const minutes = m % 60;
		const hours = (m - minutes) / 60;
		return {
			hours: hours,
			minutes: minutes,
			seconds: seconds,
		};
	};
	const getTime = (timeDiff: number) => {
		const { hours, minutes, seconds } = getTimePartsFromDuration(timeDiff);
		return [hours, minutes, seconds]
			.map((digit) => digit.toString().padStart(2, "0"))
			.join(":");
	};

	const handleAudioEffect = (event: any) => {
		let message = {
			action: "audio_effect",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			audio_effect: event.target.value === "none" ? "" : event.target.value,
		};
		WebsocketClientUSerStatus.handleAudioEffect(message);
	};

	const onMessageReceived = (responseMsg: any) => {
		if (responseMsg.action === "audio_recording") {
			handleRecording(responseMsg);
		}
	};

	const onMessageReceivedAudioEffect = (responseMsg: any) => {
		if (
			responseMsg.action === "audio_effect" &&
			responseMsg.metadata.audio_effect_status === "SUCCESS"
		) {
			setAudioEffect(
				responseMsg.metadata.audio_effect === ""
					? "none"
					: responseMsg.metadata.audio_effect
			);
			localStorage.setItem(
				"sessionAudioEffect",
				responseMsg.metadata.audio_effect === ""
					? "none"
					: responseMsg.metadata.audio_effect
			);
		}
	};

	const handleEmoji = (emojiObj: any) => {
		let message = {
			action: "emoji",
			identity: user.email,
			key: emojiObj.key,
			twilioSessionId: twilioSessionId,
		};
		WebsocketClientUSerStatus.handleEmojiChange(message);
		setAnchorEl(null);
	};

	const toggleEmojis = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		if (anchorEl === null) {
			setIsChatWindowOpen(false);
			setIsPeopleWindowOpen(false);
			setMixerOpen(false);
			localStorage.setItem("isMixerOpen", "false");
		}
	};

	const togglePeopleWindow = () => {
		setIsPeopleWindowOpen(!isPeopleWindowOpen);
		setIsChatWindowOpen(false);
		setAnchorEl(null);
		setMixerOpen(false);
		localStorage.setItem("isMixerOpen", "false");
	};

	const toggleMixer = () => {
		localStorage.setItem("isMixerOpen", isMixerOpen ? "false" : "true");
		setMixerOpen(!mixerOpen);
		setIsChatWindowOpen(false);
		setIsPeopleWindowOpen(false);
		setAnchorEl(null);
	};

	const toggleChat = () => {
		setMixerOpen(false);
		localStorage.setItem("isMixerOpen", "false");
		setIsPeopleWindowOpen(false);
		setAnchorEl(null);
	};

	const toggleRecording = () => {
		console.log(
			isRecordingStarted +
				" isRecordingStarted test test test " +
				_isStorageAvaiable
		);
		if (_isStorageAvaiable) {
			console.log("storage avaiable");
		}
		//
		setDisabledRecordButton(true);
		setTimeout(() => {
			// After 5 seconds, set the variable to false
			setDisabledRecordButton(false);
		}, 5000);
		let message = {
			action: "audio_recording",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			recordingStatus: !isRecordingStarted
				? Constants.RecordingCmd.START
				: Constants.RecordingCmd.STOP,
		};
		WebsocketClientUSerStatus.handleRecordingControl(message);
	};

	const handleRecording = (response: any) => {
		console.log("check for storage capacity");
		if (response.metadata.recording_details.recording_status === "STARTED") {
			localStorage.setItem("recordingStatus", "Started");
			setIsRecordingStarted(true);

			const isInitiator = response.metadata.created_by === user.email;
			setRecordingInitiator(isInitiator);
			localStorage.setItem("recordingInitiator", response.metadata.created_by);

			setOpen(true);
			const sTime = new Date();

			globalThis.clearInterval(recordingIntervalRef.current);
			startRecordingTimer(sTime);
			fetchSessionDetails();

			// Set disable based on whether the current user started the recording or not
			if (!isInitiator) {
				setIsRecordingDisable(true); // Disable controls for users who did not start the recording
			}
		}

		if (
			response.metadata.recording_details.recording_status === "STOPPED" ||
			response.metadata.recording_details.recording_status === "FAILED"
		) {
			localStorage.setItem("recordingStatus", "Stopped");
			localStorage.removeItem("recordingInitiator");

			if (userSession.recordingStatus) {
				userSession.recordingStatus = false;
				localStorage.setItem("userSession", JSON.stringify(userSession));
			}

			setIsRecordingStarted(false);
			setRecordingInitiator(false);

			globalThis.clearInterval(recordingIntervalRef.current);
			setRecordingTimer("");

			// Set disable based on whether the current user stopped the recording or not
			if (response.metadata.created_by !== user.email) {
				setIsRecordingDisable(false); // Enable controls if the recording was stopped by another user
			}
		}
	};

	const startRecordingTimer = (sTime: any) => {
		// if (recordingIntervalRef.current === undefined) {
		recordingIntervalRef.current = setInterval(() => {
			var timeDiff = Date.now() - sTime.getTime();
			setRecordingTimer(timeDiff);
		}, 1000);
		// }
	};

	const toggleFullscreenMode = () => {
		if (!isFullscreenMode) {
			document.body.requestFullscreen();
			setIsFullscreenMode(true);
		} else {
			document.exitFullscreen();
			setIsFullscreenMode(false);
		}
	};

	const handleCloseSessionMenu = (itemId: string) => {
		setAnchorElMenu(null);
		if (itemId === "hideTab") {
			setIsTabHidden(!isTabHidden);
		} else if (itemId === "sessionInfo") {
			setSessionInformationDialogBoxOpen(!sessionInformationDialogBoxOpen);
		} else if (itemId === "liveStreaming") {
			if (userSession?.livestreamStatus) {
				let requestMsg = {
					action: "session_broadcasting",
					timestamp: new Date(),
					session_id: twilioSessionId,
					created_by: user.id,
					broadcastingStatus: "STOP",
				};
				WebsocketClientUSerStatus.handleBroadcasting(requestMsg);
			} else {
				let requestMsg = {
					action: "session_broadcasting",
					timestamp: new Date(),
					session_id: twilioSessionId,
					created_by: user.id,
					broadcastingStatus: "START",
				};
				WebsocketClientUSerStatus.handleBroadcasting(requestMsg);
				setBroadcastDialogOpen(!broadcastDialogOpen);
			}
		}
	};

	const handleOpenSessionMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElMenu(event.currentTarget);
	};

	const onMessageReceivedBroadcasting = (response: any) => {
		if (response?.metadata?.status === "STARTED") {
			if (!(response?.metadata?.created_by === user.email)) {
				setIsDisable(true);
			}
			localStorage.setItem(
				"userSession",
				JSON.stringify({
					...userSession,
					livestreamStatus: true,
				})
			);
			setIsBroadcastingStarted(true);
			setIsLiveStreaming(true);
		} else {
			localStorage.setItem(
				"userSession",
				JSON.stringify({
					...userSession,
					livestreamStatus: false,
				})
			);
			setIsBroadcastingStarted(false);
			setIsLiveStreaming(false);
			setIsDisable(false);
		}
	};
	useEffect(() => {}, [broadcastResponse]);

	useEffect(() => {}, [streamStartedBy]);

	const closeBroadcastDialog = () => {
		setBroadcastDialogOpen(false);
	};

	const closeSessionInformationDialogBox = () => {
		setSessionInformationDialogBoxOpen(false);
	};

	useEffect(() => {
		document.addEventListener("fullscreenchange", (event) => {
			if (document.fullscreenElement) {
				setIsFullscreenMode(true);
			} else {
				setIsFullscreenMode(false);
			}
		});
	}, []);

	const fetchSessionDetails = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/session-details/${userSession.id}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status >= 400) {
				} else {
					return res.json();
				}
			})
			.then((session: SessionInterface) => {
				localStorage.setItem("userSession", JSON.stringify(session));
			});
	};

	return (
		<div style={{ width: "100% !important" }}>
			<AppBar
				position="fixed"
				className={classes.container}
				style={{
					top: isTabHidden ? "5px" : isFullscreenMode ? "0px" : "70px",
					width: "100%",
				}}
			>
				<Toolbar
					sx={{
						display: "flex",
						width: "100% !important ",
						maxWidth: "100% !important",
					}}
					className="sessionMenu"
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							paddingLeft: "0p",
							width: "20%",
						}}
					>
						<Box sx={{ width: "80px !important" }}>
							<Typography
								sx={{
									fontSize: "1rem",
									marginRight: "5px !important",
									color: "#C5C5C5",
								}}
							>
								{isRecordingStarted && recordingTimer
									? getTime(recordingTimer)
									: ""}
							</Typography>
						</Box>
						{/* <Divider
							className="jamsession-divider"
							sx={{ margin: "0 2% !important" }}
							orientation="vertical"
						/> */}
						<Box
							sx={{ margin: "0px 10px !important" }}
							className="sessionTitle"
						>
							<Typography
								sx={{
									color: isRecordingStarted ? "#FF7070" : "#C5C5C5",
									fontSize: "1rem",
								}}
							>
								{isRecordingStarted
									? "Recording session"
									: userSession.sessionName ??
									  room!.name.substr(room!.name.lastIndexOf("_") + 1)}
							</Typography>
						</Box>
					</div>
					<div style={{ display: "flex", alignItems: "center", width: "80%" }}>
						<Box sx={{ pr: "10px !important", width: "55px" }}>
							<Typography
								sx={{ color: "#C5C5C5", fontSize: "14px", fontWeight: "500" }}
								className="sessionTitle"
							>
								{i18n.jamSession.effects}
							</Typography>
						</Box>
						<Box sx={{ pr: "15px !important" }}>
							<Select
								id="demo-simple-select"
								value={audioEffect}
								MenuProps={{
									PaperProps: {
										style: {
											backgroundColor: "black",
											color: "#cfd9df",
											border: "1px solid #5c5c5c",
											boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											borderRadius: "12px",
										},
									},
								}}
								sx={{
									border: "1px solid #5C5C5C",
									borderRadius: "6px",
									color: "#CFD9DF",
								}}
								onChange={handleAudioEffect}
								className="sessionSelect"
							>
								{audioEffects.map((effect: any) => (
									<MenuItem
										sx={{ p: 0 }}
										value={effect.value}
										key={effect.value}
									>
										<ListItemButton sx={{ padding: "0 16px" }}>
											<ListItemText
												primary={effect.label}
												primaryTypographyProps={{
													style: { color: "#CFD9DF" },
												}}
											/>
										</ListItemButton>
									</MenuItem>
								))}
							</Select>
						</Box>
						<Divider
							className="jamsession-divider"
							sx={{ margin: "0 2% !important" }}
							orientation="vertical"
						/>

						<IconButton sx={{ p: 0 }} className="sessionIconBtn">
							<ToggleScreenShareButton disabled={isReconnecting} />
						</IconButton>
						<div className={Boolean(anchorEl) ? "active-menu" : ""}>
							<Tooltip title={i18n.jamSession.emojiTooltip}>
								<IconButton
									sx={{ p: 0 }}
									className="sessionIconBtn"
									onClick={toggleEmojis}
								>
									<img src={Smileys} alt="Smileys" />
								</IconButton>
							</Tooltip>
						</div>
						<Popper
							id="simple-popper"
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							placement="bottom"
							sx={{ zIndex: 10 }}
						>
							<Box
								sx={{
									p: 1,
									bgcolor: "#000000",
									mt: "15px",
									border: "1px solid #5C5C5C",
									boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
									borderRadius: "6px",
								}}
							>
								<div>
									{Constants.emojis.map((emojiObj: any) => (
										<IconButton
											key={emojiObj.key}
											sx={{
												color: "grey",
											}}
											onClick={() => handleEmoji(emojiObj)}
										>
											{emojiObj.emoji}
										</IconButton>
									))}
								</div>
							</Box>
						</Popper>
						<div className={isPeopleWindowOpen ? "active-menu" : ""}>
							<Tooltip
								title={
									isPeopleWindowOpen
										? i18n.jamSession.hidePeoplesTooltip
										: i18n.jamSession.peoplesTooltip
								}
							>
								<IconButton
									sx={{ p: 0 }}
									className="sessionIconBtn"
									onClick={togglePeopleWindow}
								>
									<img src={People} alt="People" />
								</IconButton>
							</Tooltip>
						</div>
						<div className={isChatWindowOpen ? "active-menu" : ""}>
							<Tooltip
								title={
									isChatWindowOpen
										? i18n.jamSession.hidechatTooltip
										: i18n.jamSession.chatTooltip
								}
							>
								<IconButton
									sx={{ p: "0 !important" }}
									className="sessionIconBtn"
								>
									<ToggleChatButton toggleChat={toggleChat} />
								</IconButton>
							</Tooltip>
						</div>
						<Tooltip
							title={
								isFullscreenMode
									? i18n.jamSession.stopFullscreenTooltip
									: i18n.jamSession.fullscreenTooltip
							}
						>
							<IconButton
								id="go-button"
								sx={{ p: 0 }}
								className="sessionIconBtn"
								onClick={toggleFullscreenMode}
							>
								<img
									src={isFullscreenMode ? StopFullscreen : StartFullscreen}
									alt="Start Fullscreen"
								/>
							</IconButton>
						</Tooltip>

						<div>
							<Tooltip title={i18n.jamSession.more}>
								<IconButton
									aria-controls={
										Boolean(anchorElMenu) ? "session-menu" : undefined
									}
									sx={{ p: 0 }}
									className="sessionIconBtn"
									onClick={handleOpenSessionMenu}
								>
									<Badge
										color="error"
										variant="dot"
										invisible={
											isTabHidden ||
											(isBroadcastingStarted && isLiveStreaming) ||
											userSession.livestreamStatus
												? false
												: true
										}
									>
										<img src={SessionMenuIcon} alt="session-menu" />
									</Badge>
								</IconButton>
							</Tooltip>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElMenu}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								open={Boolean(anchorElMenu)}
								onClose={handleCloseSessionMenu}
								MenuListProps={{
									"aria-labelledby": "session-menu",
								}}
							>
								{SessionMenu.map((menu) => (
									<ListItemButton
										disabled={
											(menu.id === "hideTab" && isFullscreenMode) ||
											menu.id === "changeLayout" ||
											menu.id === "liveStreaming" ||
											(menu.id === "liveStreaming" && isDisable) ||
											(menu.id === "liveStreaming" &&
												userSession.livestreamStatus)
										}
										key={menu.label}
										onClick={() => handleCloseSessionMenu(menu.id)}
									>
										{menu.id === "liveStreaming" ? (
											<ListItemIcon
												sx={{
													minWidth: "35px",
													opacity: isDisable ? 0.5 : 1,
													pointerEvents: isDisable ? "none" : "auto",
												}}
											>
												<img
													src={
														(isBroadcastingStarted && isLiveStreaming) ||
														userSession.livestreamStatus
															? EndLiveStreaming
															: LiveStreaming
													}
													alt={menu.label}
												/>
											</ListItemIcon>
										) : menu.id === "hideTab" ? (
											<ListItemIcon sx={{ minWidth: "35px" }}>
												<img
													src={isTabHidden ? ShowTab : HideTab}
													alt={menu.label}
												/>
											</ListItemIcon>
										) : menu.id === "sessionInfo" ? (
											<ListItemIcon sx={{ minWidth: "35px" }}>
												<img src={SessionInfo} alt={menu.label} />
											</ListItemIcon>
										) : (
											<ListItemIcon sx={{ minWidth: "35px" }}>
												<img src={menu.icon} alt={menu.label} />
											</ListItemIcon>
										)}
										<ListItemText
											primary={
												menu.id === "hideTab"
													? isTabHidden
														? "Show tab bar"
														: "Hide tab bar"
													: menu.id === "liveStreaming"
													? (isBroadcastingStarted && isLiveStreaming) ||
													  userSession.livestreamStatus
														? "End Streaming"
														: menu.label
													: menu.label
											}
										/>
									</ListItemButton>
								))}
							</Menu>
						</div>
						<Divider
							className="jamsession-divider"
							sx={{ margin: "0 2% !important" }}
							orientation="vertical"
						/>
						<AudioControl />
						<IconButton sx={{ p: 0 }} className="sessionIconBtn">
							{/* <img src={Video} alt="Video" /> */}
							{/* {!isSharingScreen && ( */}
							<ToggleVideoButton disabled={isReconnecting} />
							{/* )} */}
						</IconButton>
						<Tooltip
							title={
								user.storageUsage >= user.storageCapacity
									? i18n.jamSession.storageFullTooltip
									: isRecordingStarted
									? recordingDisable
										? i18n.jamSession.disableStopRecording
										: i18n.jamSession.stopRecordingTooltip
									: i18n.jamSession.startRecordingTooltip
							}
							disableInteractive
						>
							<div>
								<IconButton
									sx={{ p: 0 }}
									className="sessionIconBtn"
									onClick={() => toggleRecording()}
									//disabled={isRecordingStarted && !recordingInitiator}
									disabled={
										disabledRecordButton ||
										user.storageUsage >= user.storageCapacity ||
										recordingDisable
									}
								>
									{isRecordingStarted ? (
										<img src={ActiveRecording} alt="Record" />
									) : (
										<img src={Recording} alt="Record" />
									)}
								</IconButton>
							</div>
						</Tooltip>
						<Tooltip
							title={
								mixerOpen
									? i18n.jamSession.closeMixerTooltip
									: i18n.jamSession.mixerTooltip
							}
						>
							<IconButton
								sx={{
									p: 0,
									backgroundColor: mixerOpen ? "#FFF069 !important" : "",
									borderRadius: "6px",
								}}
								className="sessionIconBtn"
								onClick={toggleMixer}
							>
								{mixerOpen ? (
									<img src={ActiveMixer} alt="active=mixer" />
								) : (
									<img src={MixerIcon} alt="mixer" />
								)}
							</IconButton>
						</Tooltip>
						<Box sx={{ justifyContent: "flex-end", ml: "10px" }}>
							<EndCallButton
								broadcastResponse={broadcastResponse}
								streamStartedBy={streamStartedBy}
							/>
						</Box>
					</div>
				</Toolbar>
				{isRecordingStarted && (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopRight": {
								top: isFullscreenMode ? "70px" : "140px",
								"& .MuiSnackbarContent-root": {
									background: "#000",
									border: "1px solid #5C5C5C",
									borderRadius: "6px",
									color: "#C5C5C5",
								},
							},
						}}
						open={open}
						onClose={() => setOpen(false)}
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
						message="Recording has started for all users"
						autoHideDuration={3000}
						action={
							<IconButton onClick={() => setOpen(false)}>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						}
					/>
				)}
			</AppBar>
			<Mixer mixer={mixerOpen} />
			<SessionBroadcastDialog
				twilioSessionId={twilioSessionId}
				broadcastDialogOpen={broadcastDialogOpen}
				closeBroadcastDialog={closeBroadcastDialog}
			/>
			<SessionInformation
				twilioSessionId={twilioSessionId}
				sessionInformationDialogBoxOpen={sessionInformationDialogBoxOpen}
				closeSessionInformationDialogBox={closeSessionInformationDialogBox}
				isBroadcastingStarted={isBroadcastingStarted}
			/>
			{/* <SnackbarAlert /> */}
		</div>
	);
};

export default memo(MenuBar);
