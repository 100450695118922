import { useEffect } from "react";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { Button, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { useWakeLock } from "react-screen-wake-lock";

import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import useParticipantsContext from "../../../hooks/useParticipantsContext/useParticipantsContext";
import { useUser } from "../../../context/UserContext";
// import WebsocketClient from "../../../services/WebsocketClient";
import Leave from "../../../images/phone-leave.svg";
import { useFullscreenModeContext } from "../../../context/FullscreenModeContext";
import { stopSessionRecording } from "../../../utils";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";
import { useBroadcastingStatusContext } from "../../../context/BroadcastingStatusContext";
// import WebsocketMetricsClient from "../../../services/WebsocketMetricsClient";
import { UserInterface } from "../../../types/types";
import { Constants } from "../../../services/constants";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			background: "#E11E1E !important",
			color: "white",
			"&:hover": {
				background: "#E11E1E !important",
			},
			padding: "5px 15px",
			borderRadius: "6px",
		},
	})
);

export default function EndCallButton(props: {
	className?: string;
	broadcastResponse?: any;
	streamStartedBy?: any;
}) {
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const navigate = useNavigate();
	const user = useUser();
	const recordingInitiator = localStorage.getItem("recordingInitiator");
	const recordingStatus = localStorage.getItem("recordingStatus");

	const classes = useStyles();
	const { room } = useVideoContext();
	const { galleryViewParticipants } = useParticipantsContext();
	const { setIsFullscreenMode, setIsTabHidden, isTabHidden } =
		useFullscreenModeContext();
	const {
		isBroadcastingStarted,
		setIsBroadcastingStarted,
		isLiveStreaming,
		setIsLiveStreaming,
	} = useBroadcastingStatusContext();
	const userSession = JSON.parse(localStorage.getItem("userSession") || "");

	useEffect(() => {
		const onMessageReceived = (payloadData: any) => {
			if (props?.streamStartedBy === user?.email) {
				WebsocketClientUSerStatus.disconnectWebsocketConnection(
					payloadData,
					user
				);
			}
		};
		WebsocketClientUSerStatus.on("leaveSession", onMessageReceived);
		return () => {
			WebsocketClientUSerStatus.off("leaveSession", onMessageReceived);
		};
	}, []);

	const handleRecording = () =>{
		if(recordingInitiator === user.email && recordingStatus === "Started"){
			let message = {
				action: "audio_recording",
				timestamp: new Date().toISOString(),
				session_id: twilioSessionId,
				created_by: user.id,
				recordingStatus: Constants.RecordingCmd.STOP,
			};

			WebsocketClientUSerStatus.handleRecordingControl(message);
		}
	}

	const leaveRoom = () => {
		handleRecording();
		if (isTabHidden === false) {
			setIsTabHidden(isTabHidden);
		} else {
			setIsTabHidden(false);
		}
		localStorage.setItem("checked", JSON.stringify(false));
		localStorage.removeItem("userCheckedMap");
		if (props?.streamStartedBy === user?.email) {
			setIsFullscreenMode(false);
			// setIsTabHidden(false);
			// localStorage.removeItem("session_attendees");
			// const sessionAttendees = JSON.parse(localStorage.getItem("session_attendees") || "");
			const storedAttendees = localStorage.getItem("session_attendees");
			const sessionAttendees = storedAttendees
				? JSON.parse(storedAttendees)
				: [];
			var message = {
				action: "session_stop",
				timestamp: new Date().toISOString(),
				session_id: twilioSessionId,
				created_by: user.id,
				unsubscribe:
					galleryViewParticipants && galleryViewParticipants.length === 0
						? true
						: false,
			};

			let requestMsg = {
				action: "session_broadcasting",
				timestamp: new Date(),
				session_id: twilioSessionId,
				created_by: user.id,
				broadcastingStatus: "STOP",
			};
			setIsLiveStreaming(false);
			userSession.livestreamStatus = false;
			setIsBroadcastingStarted(false);
			// WebsocketMetricsClient.disconnectWebsocketConnection();
			WebsocketClientUSerStatus.handleBroadcasting(message);
			WebsocketClientUSerStatus.handleLeaveSession(message);
			WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
			WebsocketClientUSerStatus.handleBroadcasting(requestMsg);
		} else {
			var message = {
				action: "session_stop",
				timestamp: new Date().toISOString(),
				session_id: twilioSessionId,
				created_by: user.id,
				unsubscribe:
					galleryViewParticipants && galleryViewParticipants.length === 0
						? true
						: false,
			};
			setIsLiveStreaming(true);
			userSession.livestreamStatus = true;
			setIsBroadcastingStarted(true);
			WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
			// WebsocketMetricsClient.disconnectWebsocketConnection();
			WebsocketClientUSerStatus.handleLeaveSession(message);
		}
		room?.disconnect();
		navigate("/ratejamsession");
	};

	return (
		<Tooltip title="Leave Session">
			<Button
				onClick={() => leaveRoom()}
				className={clsx(classes.button, props.className)}
				data-cy-disconnect
				startIcon={<img src={Leave} alt="leave session" />}
			>
				Leave
			</Button>
		</Tooltip>
	);
}
