import React, { useState, useEffect } from 'react';
import Comment from './Comment';
import { Autocomplete, Avatar, Button, Grid, IconButton, InputAdornment, TextField, Typography, Box, Divider } from '@mui/material';
import CommentPostIcon from '../../images/Posts/comment-post.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import Picture from '../../images/Posts/picture-upload-blue.svg';
import Music from '../../images/Posts/music-upload-blue.svg';
import { makeStyles } from "@mui/styles";
import SelectMusic from './selectMusic';
import { useUser } from '../../context/UserContext';
import ErrorAlert from '../Error/ErrorAlert';
import PictureView from './PictureView';
import CancelIcon from '../../images/Group 4282.svg';
import CustomAudioPlayer from '../../pages/CustomAudioPlayer';
import AddComment from './AddComment';
import { useAdminContext } from "../../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";


const useStyles = makeStyles((theme: any) => ({

  root: {
    '& .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator': {
      color: '#EEEEEE',
      display: 'none'
    },
    '& .css-dnxhuh-MuiInputBase-root-MuiInput-root:after': {
      borderBottom: 'none'
    },
    '& .css-dnxhuh-MuiInputBase-root-MuiInput-root:before': {
      borderBottom: 'none'
    },
    '& .css-dnxhuh-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled,.Mui-error):before': {
      borderBottom: 'none'
    },
    '& .css-1515x7o:before': {
      borderBottom: 'none'
    },
    '& .css-1515x7o:after': {
      borderBottom: 'none'
    },
    '& .css-1515x7o:hover:not(.Mui-disabled,.Mui-error):before': {
      borderBottom: 'none'
    },
  }
}));

// const comments = [
//   {
//     createdAt: '',
//     "parentCommentId": ,
//     “postId”:,
//     “userProfileId”:,
//     "contentMessage": “”,
//     “musicId”:,
//     “photo”:,
//     “totalReactions": ,
//     "reacted": true
//     “childComments”:[],
// }
// ]

const CommentList = ({ commentDto, postId, setTotalCommentCount, totalCommentCount, postCreateBy }: any) => {
  console.log("test comment DTO:",commentDto );
  const user = useUser();
  const [postPrivacy, setPostPrivacy] = useState('RECENT')
  const token = localStorage.getItem("sessionId");
  const [loading, setLoading] = useState(false);
  const [moreComments, setMoreComments] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [displayLoadMore, setDisplayLoadMore] = useState(commentDto && commentDto.length > 1 && totalCommentCount > 2)
  const [parentCommentUserName, setParentCommentUserName] = useState('');
  const [parentCommentId, setParentCommentId] = useState('');
  const [displayAddReply, setDisplayAddReply] = useState(false)
  const [selectedId, setSelectedId] = useState('')


  




  useEffect(() => {
    setMoreComments([...commentDto])
    setDisplayLoadMore(commentDto && commentDto.length > 1 && totalCommentCount > 2)
  }, [commentDto])

  const handleLoadMoreComments = () => {
    setLoading(true);
    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/comments/allComments/tree?sortBy=${postPrivacy}&page=${pageNumber}&size=${itemPerPage}`,
        // `${process.env.REACT_APP_BASE_URL}/api/getAllPosts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            postId: postId
          })
        }
      )
        .then((res) => {
          if (res.status > 400) {
            throw new Error("api_error");
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setDisplayLoadMore(data.length === itemPerPage)
          if (data.length > 0) {
            if (pageNumber === 0) {
              setMoreComments(data)
            } else {
              setMoreComments([...moreComments, ...data])
            }

            var nextPageNumber = pageNumber + 1;
            setPageNumber(nextPageNumber);
          }
          if (data.length < itemPerPage) {
            setHasMore(false);
          }
        })
        .catch((err) => {
          setHasMore(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }

  }
  const handleRefreshComments = () => {
    setLoading(true);
    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/comments/allComments/tree?sortBy='RECENT'&page=0&size=${itemPerPage}`,
        // `${process.env.REACT_APP_BASE_URL}/api/getAllPosts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            postId: postId
          })
        }
      )
        .then((res) => {
          if (res.status > 400) {
            throw new Error("api_error");
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setDisplayLoadMore(data.length === itemPerPage)
          if (data.length > 0) {
            if (pageNumber === 0) {
              setMoreComments(data)
            } else {
              setMoreComments([...moreComments, ...data])
            }

            var nextPageNumber = pageNumber + 1;
            setPageNumber(nextPageNumber);
          }
          if (data.length < itemPerPage) {
            setHasMore(false);
          }
        })
        .catch((err) => {
          setHasMore(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }

  }



  return (
    <>
      <Divider sx={{ color: '#C5C5C5', mb: 2 }} />


      <Grid container direction={'column'} rowGap={1} sx={{ px: 2 }}>
        {(user.userAccountStatus === "SUSPEND" || user.authorities?.[0].name === "ROLE_ADMIN" ) ? '': 
          <AddComment postId={postId} sortComment={true} parentCommentId='0' setMoreComments={setMoreComments}
            moreComments={moreComments} setPostPrivacy={setPostPrivacy} postPrivacy={postPrivacy}
            totalCommentCount={totalCommentCount} setTotalCommentCount={setTotalCommentCount} />
        }
      



        <Grid item container>
          {moreComments.map((comment: any, index: any) => (
            <>
              <Comment key={index} {...comment}
                totalCommentCount={totalCommentCount} setTotalCommentCount={setTotalCommentCount}
                setMoreComments={setMoreComments}
                moreComments={moreComments} setPostPrivacy={setPostPrivacy} postPrivacy={postPrivacy} isFirst={true}
                AuthorName={comment.userProfileName} setParentCommentUserName={setParentCommentUserName} setParentCommentId={setParentCommentId}
                setDisplayAddReply={setDisplayAddReply}
                postCreateBy={postCreateBy}
                setSelectedId={setSelectedId} parentId={comment.id} handleRefreshComments={handleRefreshComments} >
                {comment.childComments}
              </Comment>
              {displayAddReply && selectedId === comment.id ?
                user.userAccountStatus === "SUSPEND" ? '' :
                  <AddComment postId={postId} sortComment={false}
                    parentCommentId={parentCommentId}
                    parentCommentUserName={parentCommentUserName}
                    // totalChildCommentCount={totalCommentCount} setTotalChildCommentCount={setTotalCommentCount}
                    setMoreComments={setMoreComments}
                    moreComments={moreComments} setPostPrivacy={setPostPrivacy} postPrivacy={postPrivacy} isReplay={true} />

                : ''}
            </>
          ))}

          {displayLoadMore ?
            <Button variant="text" sx={{ color: '#7A7985', fontSize: '16px' }} onClick={() => handleLoadMoreComments()}>Load more comments</Button>
            : ''}
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default CommentList;