import {
	Avatar,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField,
	Typography,
	Button,
	Divider,
	FormHelperText,
	Select,
	MenuItem,
	Stack,
} from "@mui/material";
import React from "react";
import { useUser } from "../../context/UserContext";
import { useState, useEffect, useRef, ChangeEvent } from "react";
import PictureView from "./PictureView";
import MusicView from "./MusicView";
// icons
import Close from "../../images/Posts/close.svg";
import PrivacyIcon from "../../images/Posts/share-post-privacy-icon.svg";
import Send from "../../images/Posts/send.svg";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SelectMusic from "./selectMusic";
import Picture from "../../images/Posts/picture-upload-blue.svg";
import Music from "../../images/Posts/music-upload-blue.svg";
import SettingsAlert from "../../pages/SettingsAlert";
import { useAlertStatus } from "../../context/AlertStatusContext";
import ChangeImageDialog from "./ChangeImageDialog";
import EventView from "./EventView";
import GigView from "./GigView";
import Gigs from "../../images/Posts/post-a-gigs.svg";
import Event from "../../images/Posts/post-on-event.svg";
import PostEvent from "./PostEvent";
import PostGig from "./PostGig";
import { useNavigate } from "react-router-dom";
interface User {
	id: number;
	profilePhotoUrl: string | null;
	profileTitle: string | null;
	// Add other properties as needed
	nickname: string | null;
	user: {
		login: string;
		firstName: string;
		lastName: string;
		// Add other properties as needed
	};
}
interface TagRecord {
	taggedUserProfile: { id: number };

	// Add other properties if there are any
}
const privacyList = [
	{ label: "Share with all", value: "Share with all" },
	{ label: "Share with My connection", value: "Share with My connection" },
];

const CreatePostNew = (props: any) => {
	const navigate = useNavigate();
	const SelfDetails = useUser();
	const token = localStorage.getItem("sessionId");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [postPrivacy, setPostPrivacy] = useState<any>("Share with all");
	const [postText, setPostText] = useState("");
	const [music, setMusic] = useState<any>("");
	const [file, setFile] = useState<any>("");
	const [result, setResult] = useState("");
	const [imagePreviewUrl, setImagePreviewUrl] = useState<any>("");
	const [checkedToFaceBook, setCheckedToFaceBook] = useState(false);
	const [checkedToTwitter, setCheckedToTwitter] = useState(false);
	const [musicOpen, setMusicOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [postId, setPostId] = useState("");
	const [dirtyPostText, setDirtyPosText] = useState(false);
	const [posting, setPosting] = useState(false);
	const [event, setEvent] = useState<any>("");
	const [gig, setGig] = useState<any>("");
	const { updateAlertStatus } = useAlertStatus();
	const [profileImageUrl, setProfileImageUrl] = useState("");
	const [isEventDialogOpen, setEventDialogOpen] = useState(false);
	const [isGigDialogOpen, setGigDialogOpen] = useState(false);
	const [deviceMusic, setDeviceMusic] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [musicFromDevice, setMusicFromDevice] = useState<any>("");
	const inputFileRef = useRef<any>(null);
	// useEffect(()=>{
	//     setPostPrivacy('Share with all')
	// }, [])
	const [userList, setUserList] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState<string>("");
	const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
	const [autocompleteOptions, setAutocompleteOptions] =
		React.useState<User[]>();
	const [prevtaggedUserList, setPrevTaggedUserList] = useState<number[]>([]);
	const [isAtTyped, setIsAtTyped] = useState(false);
	const [fullNameArray, setFullNameArray] = useState<
		{ id: any; name: string }[]
	>([]);
	const maxSize = 4 * 1024 * 1024;
	const imageExtensions = ["jpg", "jpeg", "png", "gif"];
	const imageMimeTypes = ["image/jpeg", "image/png", "image/gif"];

	let searchTerm = "";
	const closeCreatePostDialog = (success?: boolean | false) => {
		setPostText("");
		setMusic("");
		setImagePreviewUrl("");
		setFile("");
		setResult("");
		setEvent("");
		setGig("");
		setPrevTaggedUserList([]);
		setFullNameArray([]);
		setIsAtTyped(false);
		props.handleClose(success);
	};
	useEffect(() => {
		setProfileImageUrl(
			SelfDetails.profilePhotoUrl
				? `${process.env.REACT_APP_PICTURE_URL}${SelfDetails.profilePhotoUrl}`
				: ""
		);
	}, [SelfDetails]);

	const postDialogRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (props) {
			setFile(props.musicFromDevice);
			setDeviceMusic(props.deviceMusic ? true : false);
			setPostText(props.propPostText);

			if (props.postVisibility === "PRIVATE") {
				setPostPrivacy("Share with My connection");
			} else {
				setPostPrivacy("Share with all");
			}

			if (props.postType === "MUSIC" && props.mediaUrl) {
				setMusic({ musicUrl: props.mediaUrl, id: props.musicId });
			}

			if (props.postType === "PHOTO") {
				setImagePreviewUrl(props.mediaUrl);
				if (props.imageInFile) {
					setFile(props.imageInFile);
				}
			}

			if (props.postType === "EVENT" && props.eventsDto) {
				setEvent(props.eventsDto);
			} else if (props.postType === "GIG" && props.gigDto) {
				setGig(props.gigDto);
			} else if (
				props.postType === "EVENT" &&
				props.eventPostDto?.type === "EVENT"
			) {
				setEvent(props.eventPostDto);
			} else if (
				props.postType === "GIG" &&
				props.eventPostDto?.type === "GIG"
			) {
				setGig(props.eventPostDto);
			}
			if (props.tagRecordsDto) {
				const ids = props.tagRecordsDto.map(
					(record: TagRecord) => record.taggedUserProfile.id
				);
				setPrevTaggedUserList(ids);
			}
		}
	}, [props]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const modifyForEditPost = (text: string) => {
		return text.replace(/<a[^>]*href=(["'])(.*?)\1[^>]*>(.*?)<\/a>/gi, "$3");
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleChangeCheckedToFaceBook = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setCheckedToFaceBook(event.target.checked);
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleChangeCheckedToTwitter = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setCheckedToTwitter(event.target.checked);
	};

	const isEnable = () => {
		var textLength = postText ? postText.trim().length : 0;

		if (textLength === 0 && !imagePreviewUrl && !music && !event && !gig) {
			return true; 
		  }
		if (
			(textLength < 3000 && textLength > 0) ||
			(imagePreviewUrl !== "" && textLength < 3000) ||
			props.editPost ||
			textLength > 0 ||
			(imagePreviewUrl && file && file.size <= maxSize && textLength < 3000) ||
			(music !== "" && textLength < 3000) ||
			(event !== "" && textLength < 3000) ||
			(gig !== "" && textLength < 3000)
		) {
			return false;
		} else {
			return true;
		}
	};
	const ArrayOfInstruments: string = gig?.masterInstruments
		? Array.from(gig.masterInstruments)
				.map((item: any) => item.id)
				.join(",")
		: "";

	const handleCreatePost = () => {
		if (file && props.postType === "PHOTO" && file.size > maxSize) {
			updateAlertStatus(true, false, "Max image size should be 4mb");
			return;
		} else {
			setPosting(true);
			let UrlToPostBackend: any;
			UrlToPostBackend = props.editPost
				? `api/updatePosts`
				: "api/post/create-post";
			let formData = new FormData();
			formData.append(
				"postType",
				gig
					? "GIG"
					: event
					? "EVENT"
					: imagePreviewUrl
					? "PHOTO"
					: music
					? "MUSIC"
					: "TEXT"
			);
			formData.append(
				"postVisibility",
				postPrivacy === "Share with all" ? "PUBLIC" : "PRIVATE"
			);
			formData.append("userProfile.id", `${SelfDetails.userProfileId}`);
			if (props.editPost) {
				formData.append("id", `${props.postId}`);
			}
			formData.append("content.contentMessage", postText);
			formData.append("musicId", music && music.id ? music.id : "");
			formData.append("file", file ? file : null);
			formData.append("content.event.id", event ? event.id : gig ? gig.id : 0);

			const taggedUserListPost: number[] = findTaggedUsers(
				fullNameArray,
				postText
			);

			//appended tagged user
			formData.append(
				"taggedUserProfileIds",
				taggedUserListPost && taggedUserListPost.length > 0
					? taggedUserListPost.join(",")
					: ""
			);
			fetch(`${process.env.REACT_APP_BASE_URL}/${UrlToPostBackend}`, {
				method: props.editPost ? "PUT" : "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			})
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status !== 200 && res.status !== 201) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						closeCreatePostDialog();
						setImagePreviewUrl("");
						setFile("");
						setMusic("");
						setPostText("");
						props.resetPostDetails();
						setPosting(false);
						return res.json();
					}
				})
				.then((data) => {
					setPostId(data.id);
					if (checkedToTwitter) {
						const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
							`${process.env.REACT_APP_BASE_URL_FRONTEND}/postView?postId=${data.id}&userProfileId=${data.userProfileId}`
						)}`;
						window.open(twitterShareUrl, "_blank", "width=600,height=400");
						setCheckedToTwitter(false);
					}
					if (checkedToFaceBook) {
						const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
							`${process.env.REACT_APP_BASE_URL_FRONTEND}/postView?postId=${data.id}&userProfileId=${data.userProfileId}`
						)}&quote=${encodeURIComponent(postText)}`;
						window.open(facebookShareUrl, "_blank", "width=600,height=400");
						setCheckedToFaceBook(false);
					}
				})
				.catch((err) => {
					setPosting(false);
				});
		}
	};

	const handleCreateEventPost = () => {
		setPosting(true);

		let formData = new FormData();
		formData.append("type", "EVENT");
		formData.append("title", event.title);
		formData.append("description", event.description);
		formData.append("cost", event.cost);
		formData.append("cityCountry", event.cityCountry);
		formData.append("venue", event.venue);
		formData.append("startDateTime", event.startDateTime);
		formData.append("endDateTime", event.endDateTime);
		formData.append("timezone", event.timezone);
		formData.append("duration", event.duration);
		formData.append("ticketLink", event.ticketLink);
		formData.append("requirements", "");
		formData.append("instruments", "");
		formData.append(
			"thumbnail",
			event.thumbnail !== undefined && event.thumbnail !== null
				? event.thumbnail
				: null
		);
		formData.append(
			"banner",
			event.banner !== undefined && event.banner !== null ? event.banner : null
		);
		formData.append(
			"thumbnailUrl",
			event.thumbnailUrl !== undefined && event.thumbnailUrl !== null
				? event.thumbnailUrl
				: null
		);
		formData.append(
			"bannerUrl",
			event.bannerUrl !== undefined && event.bannerUrl !== null
				? event.bannerUrl
				: null
		);
		formData.append("genres", event.genres);
		formData.append("latitude", event.latitude);
		formData.append("longitude", event.longitude);
		if (event.id) {
			formData.append("id", props.eventPostDto?.id);
		}

		const taggedUserListPost: number[] = findTaggedUsers(
			fullNameArray,
			postText
		);

		//appended tagged user
		formData.append(
			"taggedUserProfileIds",
			taggedUserListPost && taggedUserListPost.length > 0
				? taggedUserListPost.join(",")
				: ""
		);
		var defaultBannerUrl = `${process.env.REACT_APP_PICTURE_URL}/defaultimages/gig-banner.png`;
		var defaultThumbnailUrl = `${process.env.REACT_APP_PICTURE_URL}/defaultimages/gig-thumbnail.svg`;
		const formData1 = new FormData();
		formData1.append("type", "GIG");
		formData1.append("title", gig.title);
		formData1.append("description", "");
		formData1.append("cost", gig.cost);
		formData1.append("cityCountry", gig.cityCountry);
		formData1.append("venue", gig.venue);
		formData1.append("startDateTime", gig.startDateTime);
		formData1.append("endDateTime", gig.endDateTime);
		formData1.append("timezone", gig.timezone);
		formData1.append("duration", gig.duration);
		formData1.append("eventTicketLink", "");
		formData1.append("requirements", gig.requirements);
		formData1.append("instrumentId", ArrayOfInstruments);
		formData1.append(
			"thumbnail",
			gig.thumbnail !== undefined && gig.thumbnail !== null ? gig.thumbnail : ""
		);
		formData1.append(
			"banner",
			gig.banner !== undefined && gig.banner !== null ? gig.banner : ""
		);
		formData1.append(
			"thumbnailUrl",
			gig.thumbnailUrl !== undefined && gig.thumbnailUrl !== null
				? gig.thumbnailUrl
				: ""
		);
		formData1.append(
			"bannerUrl",
			gig.bannerUrl !== undefined && gig.bannerUrl !== null ? gig.bannerUrl : ""
		);
		formData1.append("latitude", gig.latitude);
		formData1.append("longitude", gig.longitude);
		formData1.append(
			"genres",
			gig.genres
			// ? gig.genres.map((genre: any) => genre.title).join(",") : ""
		);
		//appended tagged user
		formData1.append(
			"taggedUserProfileIds",
			taggedUserListPost && taggedUserListPost.length > 0
				? taggedUserListPost.join(",")
				: ""
		);
		if (gig.id) {
			formData1.append("id", props.eventPostDto?.id);
		}

		fetch(`${process.env.REACT_APP_BASE_URL}/api/events/create-event`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: event ? formData : formData1,
		})
			.then((response) => {
				if (response.status === 502) {
					navigate("/defaultPage");
				} else if (response.status < 400) {
					return response.json();
				} else {
					if (response.status >= 400 && response.status < 500) {
						updateAlertStatus(true, false, "Something went wrong ..");
					} else if (response.status >= 500 && response.status < 600) {
						updateAlertStatus(true, false, "Internal server error ..");
					}
					throw response;
				}
			})
			.then((result) => {
				let UrlToPostBackend: any;
				UrlToPostBackend = props.editPost
					? `api/updatePostWithEvent/${props.postId}`
					: "api/post/create-postWithEvent";
				const taggedUserListPost: number[] = findTaggedUsers(
					fullNameArray,
					postText
				);
				const postData = {
					postType: gig ? "GIG" : event ? "EVENT" : "",
					postVisibility:
						postPrivacy === "Share with all" ? "PUBLIC" : "PRIVATE",
					userProfile: {
						id: SelfDetails.userProfileId,
					},
					taggedUserProfileIds:
						taggedUserListPost && taggedUserListPost.length > 0
							? taggedUserListPost
							: taggedUserListPost,
					content: {
						contentMessage: postText,
						event: {
							id: event ? result.id : gig ? result.id : null,
						},
					},
				};

				const jsonData = JSON.stringify(postData);
				fetch(`${process.env.REACT_APP_BASE_URL}/${UrlToPostBackend}`, {
					method: props.editPost ? "PUT" : "POST",
					headers: {
						"Content-type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: jsonData,
				})
					.then((res) => {
						if (res.status === 502) {
							navigate("/defaultPage");
						} else if (res.status !== 200 && res.status !== 201) {
							ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						} else {
							closeCreatePostDialog(true);
							setImagePreviewUrl("");
							setFile("");
							setMusic("");
							setPostText("");
							props.resetPostDetails();
							setPosting(false);
							if (props.location === "details") {
								navigate("/home");
							}
							return res.json();
						}
					})
					.then((data) => {
						setPostId(data.id);
						if (checkedToTwitter) {
							const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
								`${process.env.REACT_APP_BASE_URL_FRONTEND}/postView?postId=${data.id}&userProfileId=${data.userProfileId}`
							)}`;
							window.open(twitterShareUrl, "_blank", "width=600,height=400");
							setCheckedToTwitter(false);
						}
						if (checkedToFaceBook) {
							const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
								`${process.env.REACT_APP_BASE_URL_FRONTEND}/postView?postId=${data.id}&userProfileId=${data.userProfileId}`
							)}&quote=${encodeURIComponent(postText)}`;
							window.open(facebookShareUrl, "_blank", "width=600,height=400");
							setCheckedToFaceBook(false);
						}
					})
					.catch((err) => {
						setPosting(false);
					});
			})
			.catch((e) => {
				if (e.status >= 400 && e.status < 500) {
					setShowAlert(true);
					setAlertMsg("Something Went Wrong!");
					setAlertStatus(false);
				} else if (e.status >= 500 && e.status < 600) {
					setShowAlert(true);
					setAlertMsg("Internal Server Error!");
					setAlertStatus(false);
				} else {
					setShowAlert(true);
					setAlertMsg("Please Try Again!");
					setAlertStatus(false);
				}
				setPosting(false);
			});
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleDeletePicture = () => {
		setImagePreviewUrl("");
		setFile("");
	};

	const handleDeleteMusic = () => {
		setMusic("");
	};

	const handleEditMusic = () => {
		setMusicOpen(true);
	};
	const handleEditClose = () => {
		setEditOpen(false);
		setMusicOpen(false);
		setEventDialogOpen(false);
		setGigDialogOpen(false);
	};
	const handleEventDelete = () => {
		setEvent("");
	};
	const handleGigDelete = () => {
		setGig("");
	};

	const photoUpload = (destFile: any) => {
		const reader = new FileReader();
		const file = destFile;
		reader.onloadend = () => {
			setFile(file);
			setImagePreviewUrl(reader.result as string);
		};
		reader.readAsDataURL(file);
	};

	const handleOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];

		if (!file) return; 

		const fileExtension = file.name.split(".").pop()?.toLowerCase();

		// Check both MIME type and extension
		if (
			fileExtension &&
			imageExtensions.includes(fileExtension) &&
			imageMimeTypes.includes(file.type)
		) {
			setResult(URL.createObjectURL(file));
			setFile(file);
			setEditOpen(true);

			if (inputFileRef.current) {
				inputFileRef.current.value = null; // Clear the input value

				const newInput = document.createElement("input");
				newInput.type = "file";
				newInput.accept = ".jpg, .jpeg, .png, .gif"; 
				newInput.style.display = "none";
				newInput.addEventListener("change", handleOpenEditor);
				inputFileRef.current.parentNode.replaceChild(
					newInput,
					inputFileRef.current
				);
				inputFileRef.current = newInput;
			}
		} else {
			updateAlertStatus(true, false, "Invalid file type");
		}
	};

	useEffect(() => {
		if (props.open) {
			setTimeout(() => {
				const inputField = document.getElementById(
					"myInput"
				) as HTMLInputElement | null;
				if (inputField) {
					inputField.focus();
				}
			}, 500);
		}
	}, [props.open]);

	function findTaggedUsers(
		fullNameArray: { id: number; name: string }[],
		postText: string
	): number[] {
		// Initialize taggedUserList
		const taggedList: number[] = [];

		// Regular expression to find words starting with "@"
		const taggedUserRegex = /@(\w+(?:\s+\w+){1})/g;

		// Extract tagged user names from postText
		const taggedUserNames = postText?.match(taggedUserRegex) || [];
		//	taggedList.push(...prevtaggedUserList)
		//prevtaggedUserList
		if (taggedUserNames) {
			// Loop through tagged user names
			taggedUserNames.forEach((taggedUserName) => {
				// Remove "@" from the tagged user name
				const userName = taggedUserName.trim();
				// Find the corresponding user in fullNameArray
				const matchedUser = fullNameArray.find(
					(user) => user.name === userName
				);
				// If a match is found and there are at least two words after the tagged user name
				if (matchedUser && postText?.includes(taggedUserName)) {
					taggedList.push(matchedUser.id);
					const startIndex =
						postText?.indexOf(taggedUserName) + taggedUserName.length + 1;
					const remainingText = postText?.substring(startIndex);
					const nextTwoWords = remainingText.split(" ").slice(0, 2).join(" ");
					if (fullNameArray.find((user) => user.name === nextTwoWords)) {
						//console.log(" in if 2 ")
					}
				}
			});
		}
		return taggedList;
	}
	const [searchTermt, setSearchTermt] = useState("");
	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setPostText(value);
		setInputValue(value);
		const splitInput = value.split(/[\s\n]+/);
		const lastTypedWord = splitInput[splitInput.length - 1];
		if (lastTypedWord.startsWith("@") && lastTypedWord.length >= 1) {
			const searchTerm = lastTypedWord.substring(1);
			const fetchData = () => {
				if (searchTerm.trim() === "") {
					setAutocompleteOptions([]);
					return;
				}

				fetch(
					`${process.env.REACT_APP_BASE_URL}/api/getAllTaggedUserProfileList?search=${searchTerm}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
					.then((response) => {
						if (!response.ok) {
							throw new Error(`HTTP error! Status: ${response.status}`);
						}
						return response.json();
					})
					.then((data) => {
						setAutocompleteOptions(data);
					})
					.catch((error) => {
						console.error("Error fetching data:", error);
					});
			};
			setShowAutocomplete(true);
			const debounceTimeout = setTimeout(() => {
				fetchData();
			}, 300);
			return () => {
				clearTimeout(debounceTimeout);
				setAutocompleteOptions([]);
			};
		}
		if (lastTypedWord.startsWith("@") && lastTypedWord.length < 1) {
			setAutocompleteOptions([]);
			setShowAutocomplete(false);
		} else {
			setAutocompleteOptions([]);
		}
		if (lastTypedWord.startsWith("@") && lastTypedWord.length >= 1) {
			setShowAutocomplete(true);
		} else {
			setShowAutocomplete(false);
		}

		if (value.trim() === "") {
			setPostText(" ");
		}
	};
	const handleAutocompleteSelect = (user: any, id: any, nickname: any) => {
		const startIndex = inputValue.lastIndexOf("@");
		const newText =
			inputValue.substring(0, startIndex) +
			`@${user.firstName} ${user.lastName} `;
		setInputValue(newText);
		setPostText(newText);
		setSearchTermt("");
		const selectedName = `@${user.firstName} ${user.lastName} `;
		const newEntry = { id: id, name: `@${user.firstName} ${user.lastName}` };
		setFullNameArray([...fullNameArray, newEntry]);
		setShowAutocomplete(false);
		setIsAtTyped(false);

		setTimeout(() => {
			postDialogRef.current?.focus();
		}, 0);
	};

	return (
		<>
			<SettingsAlert />
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
					p: 2,
					minWidth: 600,
				}}
				fullWidth
				maxWidth="sm"
				open={props.open}
			>
				<DialogTitle sx={{ fontWeight: "bold", fontSize: "24px" }}>
					{" "}
					{props.editPost ? "Edit Post" : "Create a New post"}
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={() => closeCreatePostDialog(false)}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: "black",
					}}
				>
					<img src={Close} alt="close" />
				</IconButton>

				<DialogContent>
					<Grid container>
						<Grid item xs={2}>
							<Avatar
								alt={SelfDetails.firstName}
								src={profileImageUrl}
								sx={{ height: "50px", width: "50px" }}
							/>
						</Grid>
						<Grid item xs={10}>
							<Grid container direction={"column"}>
								<Grid item>
									<Typography
										variant="body2"
										sx={{ fontWeight: "500", fontSize: "1rem" }}
									>
										{`${SelfDetails.firstName} ${SelfDetails.lastName}`}
									</Typography>
								</Grid>
								<Grid container>
									{/* <Grid xs={1} sx={{ alignSelf: 'self-end', backgroundColor: '#EEEEEE', borderRadius: '20px', }} >
                                        <img src={PrivacyIcon} alt='' style={{ height: '17px',  }} />
                                    </Grid> */}
									<Grid
										item
										container
										xs={7}
										sx={{ backgroundColor: "#EEEEEE", borderRadius: "20px" }}
									>
										<img
											src={PrivacyIcon}
											alt=""
											style={{
												height: "17px",
												marginRight: "-28px",
												marginTop: "7px",
												marginLeft: "11px",
												zIndex: 1,
											}}
										/>
										<Select
											value={postPrivacy}
											onChange={(event: any) => {
												setPostPrivacy(event.target.value);
											}}
											// defaultValue={{ label: 'Share with all', value: 'Share with all' }}
											fullWidth
											sx={{
												"&:hover": {
													borderColor: "white !important",
												},
												backgroundColor: "#EEEEEE",
												borderRadius: "20px",
												pl: 3,
												height: "28px",
												"& .MuiOutlinedInput-notchedOutline": {
													borderColor: "white !important",
												},
												"& .MuiOutlinedInput-notchedOutline:focus-visible": {
													borderColor: "white",
												},
												"& .MuiOutlinedInput-notchedOutline:hover": {
													borderColor: "white",
												},
												"& .MuiOutlinedInput-root:hover": {
													borderColor: "white",
												},
												"& .MuiSelect-outlined:hover": {
													borderCollapse: "white !important",
												},
												"& .MuiSelect-root:hover": {
													borderCollapse: "white !important",
												},
												"& .MuiSelect-select:hover": {
													borderCollapse: "white !important",
												},
											}}
										>
											{privacyList.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sx={{ pt: 1 }} rowGap={1}>
							<TextField
								id="myInput"
								className="social-textField"
								ref={postDialogRef}
								multiline
								rows={4}
								maxRows={4}
								placeholder="What's New?"
								sx={{
									backgroundColor: "white",
									borderRadius: "12px !important",
									"& .css-w6av21-MuiInputBase-root-MuiOutlinedInput-root": {
										borderRadius: "12px",
									},
									"& .MuiOutlinedInput-input": {
										fontWeight: "500 !important",
										fontSize: "18px !important",
										whiteSpace: "pre-line",
									},
								}}
								value={postText}
								onChange={handleInputChange}
								variant="outlined"
								error={dirtyPostText && postText?.length > 600}
								onFocus={() => setDirtyPosText(true)}
								required
								autoFocus
							/>

							{showAutocomplete && autocompleteOptions && (
								<Stack
									sx={{
										marginTop: "-1px",
										width: "520px",
										marginLeft: "9px",
										border: "1px solid #C2C2C3",
										zIndex: -1,
										maxHeight: "225px",
										boxShadow: "rgba(217, 231, 255, 0.77) 0px 1px 14px 0px",
										overflowY: "auto",
										overflowX: "hidden",
										"&::-webkit-scrollbar": {
											display: "none",
										},
									}}
								>
									{(autocompleteOptions ?? [])
										.filter((option) => typeof option !== "string")
										.map((option, index) => (
											<Stack
												flexDirection="column"
												alignItems="flex-start"
												sx={{ width: "520px", maxHeight: "250px" }}
											>
												<Stack
													flexDirection="row"
													alignItems="center"
													//   mb={1}
													//   mt={1}
													sx={{
														width: "520px",
														height: "62px",

														"&:hover": {
															backgroundColor: "#F5F9FD",
														},
													}}
													onKeyDown={(e) => {
														if (e.key === "Enter") {
															handleAutocompleteSelect(
																option.user,
																option.id,
																option.nickname
															);
															postDialogRef.current?.focus();
														}
													}}
													onClick={() => {
														handleAutocompleteSelect(
															option.user,
															option.id,
															option.nickname
														);
														postDialogRef.current?.focus();
													}}
													onSelect={(selectedOption) => {
														// Call handleTagging with the selected option value
														handleAutocompleteSelect(
															option.user,
															option.id,
															option.nickname
														);
														// Focus back on the post dialog input field after selecting an option
														postDialogRef.current?.focus();
													}}
													tabIndex={0}
												>
													<Stack sx={{ mr: "10px", width: "61px" }}>
														<Avatar
															alt={option.user.firstName}
															src={
																option.profilePhotoUrl
																	? `${process.env.REACT_APP_PICTURE_URL}${option.profilePhotoUrl}`
																	: ""
															}
															className="profile-img-style"
															sx={{
																width: "50px",
																height: "50px",
																marginLeft: "11px",
															}}
														/>
													</Stack>
													<Stack>
														<Stack flexDirection="row" alignItems="flex-start">
															<Typography
																className="card-title-txt-sx"
																sx={{
																	fontSize: "16px",
																	color: "#000000",
																	fontWeight: 500,
																	fontFamily: "Lexend",
																	lineHeight: "20px",
																	maxWidth: "12.125rem",
																	whiteSpace: "nowrap",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																}}
															>
																{`${option.user.firstName} ${option.user.lastName} `}
															</Typography>
														</Stack>
														{option.profileTitle !== null ? (
															<Typography
																className="card-sub-title-txt-sx"
																sx={{
																	fontSize: "14px",
																	color: "#5B5B65",
																	fontWeight: 400,
																	fontFamily: "Lexend",
																	lineHeight: "17.5px",
																	maxWidth: "100%",
																	whiteSpace: "nowrap",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																	textWrap: "wrap",
																}}
															>
																{`${
																	option.profileTitle &&
																	option.profileTitle?.length > 35
																		? option.profileTitle?.substring(0, 35) +
																		  "..."
																		: option.profileTitle
																}`}
															</Typography>
														) : (
															""
														)}
													</Stack>
												</Stack>

												<hr
													style={{
														color: "red !important",
														width: "100%",
														margin: 0,
													}}
												/>
											</Stack>
										))}
								</Stack>
							)}
							{dirtyPostText && (
								<FormHelperText sx={{ color: "#FD4B24", textAlign: "right" }}>
									{postText?.length >= 3000
										? "Max characters should be 3000"
										: ""}
								</FormHelperText>
							)}
						</Grid>
						{imagePreviewUrl && (
							<Grid item xs={12} sx={{ pt: 1 }}>
								{file && file.size > maxSize && (
									<Typography sx={{ color: "#FD4B24", textAlign: "right" }}>
										Max image size should be 4mb
									</Typography>
								)}
								<PictureView
									image={imagePreviewUrl}
									handleDeletePicture={handleDeletePicture}
									handleOpenEditor={handleOpenEditor}
								/>
							</Grid>
						)}
						{music && (
							<Grid item xs={12} sx={{ pt: 1 }}>
								<MusicView
									music={
										deviceMusic
											? `${music.musicUrl}`
											: music.musicUrl.includes("https://")
											? music.musicUrl
											: `${process.env.REACT_APP_PICTURE_URL}${music.musicUrl}`
									}
									handleDeleteMusic={handleDeleteMusic}
									handleEditMusic={handleEditMusic}
								/>
							</Grid>
						)}
						{event && (
							<Grid
								item
								xs={12}
								sx={{ pt: 1, borderRadius: "12px", background: "F8F8F8" }}
							>
								<EventView
									event={event}
									setEvent={setEvent}
									handleDelete={handleEventDelete}
									isEdit={true}
									showAlert={showAlert}
									alertStatus={alertStatus}
									alertMsg={alertMsg}
									setAlertMsg={setAlertMsg}
									setAlertStatus={setAlertStatus}
									setShowAlert={setShowAlert}
									postType={props.postType}
								/>
							</Grid>
						)}
						{gig && (
							<Grid item xs={12} sx={{ pt: 1 }}>
								<GigView
									gig={gig}
									setGig={setGig}
									handleDelete={handleGigDelete}
									isEdit={true}
									showAlert={showAlert}
									alertStatus={alertStatus}
									alertMsg={alertMsg}
									setAlertMsg={setAlertMsg}
									setAlertStatus={setAlertStatus}
									setShowAlert={setShowAlert}
									editPost={props.editPost}
									postType={props.postType}
								/>
							</Grid>
						)}
					</Grid>
				</DialogContent>

				{
					// (imagePreviewUrl !== '' || music !== '') ? '' :
					imagePreviewUrl !== "" ||
					music !== "" ||
					event !== "" ||
					gig !== "" ? (
						""
					) : (
						<Grid container sx={{ pl: 2 }}>
							<Grid item xs={3} sx={{ pl: 1 }}>
								<Button
									sx={{
										color: "black",
										fontSize: "14px",
										pt: "12px",
										height: "32px",
									}}
								>
									<label
										style={{
											cursor: "pointer",
											color: "black",
											fontSize: "14px",
										}}
									>
										<Grid container>
											<img
												src={Picture}
												alt=""
												style={{ height: "24px", width: "24px" }}
											/>
											&nbsp;
											<input
												id="photo-upload"
												ref={inputFileRef}
												type="file"
												accept =".jpg, .jpeg, .png, .gif"
												style={{ display: "none" }}
												onChange={(e) => handleOpenEditor(e)}
											/>
											<Typography sx={{ color: "black", fontSize: "14px" }}>
												Photo
											</Typography>
										</Grid>
									</label>
								</Button>
							</Grid>
							<Grid item xs={3}>
								<label>
									<Button
										startIcon={
											<img
												src={Music}
												alt="Music"
												style={{ height: "24px", width: "24px" }}
											/>
										}
										onClick={() => setMusicOpen(true)}
										sx={{
											color: "black",
											fontSize: "14px",
											pb: 0.5,
											height: "32px",
										}}
									>
										<Typography sx={{ color: "black", fontSize: "13px" }}>
											{" "}
											Audio
										</Typography>
									</Button>
								</label>
							</Grid>
							<Grid item xs={3}>
								<label>
									<Button
										startIcon={
											<img src={Gigs} alt="post" style={{ height: "17px" }} />
										}
										onClick={() => setGigDialogOpen(true)}
										sx={{ color: "black", fontSize: "13px", pb: 0.5 }}
									>
										<Typography sx={{ color: "black", fontSize: "13px" }}>
											Post a Gig
										</Typography>
									</Button>
								</label>
							</Grid>
							<Grid item xs={3}>
								<label>
									<Button
										startIcon={
											<img src={Event} alt="event" style={{ height: "17px" }} />
										}
										onClick={() => setEventDialogOpen(true)}
										sx={{ color: "black", fontSize: "13px", pb: 0.5 }}
									>
										<Typography sx={{ color: "black", fontSize: "13px" }}>
											Post an Event
										</Typography>
									</Button>
								</label>
							</Grid>
						</Grid>
					)
				}

				<Divider variant="middle" light sx={{ my: 1 }} />

				<Grid container sx={{ pb: 1, px: 2 }}>
					<Grid item xs={6} container direction={"column"}>
						{/* <Grid item container >
                            <Grid item xs={2} sx={{ pl: 1 }}>
                                <Checkbox
                                    defaultChecked
                                    size="small"
                                    color='info'
                                    checked={checkedToFaceBook}
                                    onChange={handleChangeCheckedToFaceBook}
                                />
                            </Grid>&nbsp;
                            <Grid item xs={9} sx={{ mr: 2, mt: 1 }}>
                                <Typography variant="body2" sx={{ color: '#7A7985', }}>
                                    <img src={FacebookIcon} style={{ height: '18px' }} />&nbsp;
                                </Typography>
                            </Grid>

                            <Grid item container>
                                <Grid item xs={2} sx={{ pl: 1 }}>
                                    <Checkbox
                                        defaultChecked
                                        size="small"
                                        color='info'
                                        checked={checkedToTwitter}
                                        onChange={handleChangeCheckedToTwitter}
                                    />
                                </Grid>&nbsp;
                                <Grid item xs={9} sx={{ mr: 2, mt: 1 }}>
                                    <Typography variant="body2" sx={{ color: '#7A7985', height: '12px' }}>
                                        <img src={TwitterIcon} style={{ height: '18px' }} />&nbsp;
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid> */}
					</Grid>

					<Grid
						item
						xs={6}
						sx={{ textAlignLast: "right", alignSelf: "center" }}
					>
						{posting ? (
							<Button
								variant="contained"
								sx={{
									borderRadius: "17px",
									background: "#FF702A",
									width: "40%",
								}}
								disabled={true}
							>
								{" "}
								Posting..
							</Button>
						) : (
							<Button
								variant="contained"
								sx={{ borderRadius: "17px", background: "#FF702A" }}
								onClick={
									event || gig ? handleCreateEventPost : handleCreatePost
								}
								// endIcon={<img src={Send} alt="Send" />}
								disabled={isEnable()}
							>
								{" "}
								Post &nbsp;
								<img src={Send} alt="Send" />
							</Button>
						)}
					</Grid>
				</Grid>
			</Dialog>
			<ChangeImageDialog
				imagePreviewUrl={result}
				open={editOpen}
				onClose={handleEditClose}
				setResult={setFile}
				file={file}
				photoUpload={photoUpload}
				setImagePreviewUrl={setImagePreviewUrl}
				cover={false}
				post={true}
				postText={postText}
				getPostDetails={props.resetPostDetails}
				// resetPostDetails = {props.resetPostDetails}
				editPost={false}
				postId={props.postId}
			/>
			<SelectMusic
				music={music}
				setMusic={setMusic}
				musicOpen={musicOpen}
				handleMusicClose={handleEditClose}
				postText={postText}
				resetPostDetails={props.resetPostDetails}
				editPost={false}
				postId={props.postId}
				setDeviceMusic={setDeviceMusic}
				isSelectFromDevice={true}
				setMusicFromDevice={setMusicFromDevice}
				setFile={setFile}
			/>
			{isEventDialogOpen && (
				<PostEvent
					event={event}
					setEvent={setEvent}
					open={isEventDialogOpen}
					handleEventClose={handleEditClose}
					resetPostDetails={props.resetPostDetails}
					postId={props.postId}
					eventType={"post"}
					editPost={false}
					setAlertMsg={setAlertMsg}
					setAlertStatus={setAlertStatus}
					setShowAlert={setShowAlert}
				/>
			)}
			{isGigDialogOpen && (
				<PostGig
					gig={gig}
					setGig={setGig}
					open={isGigDialogOpen}
					handleGigClose={handleEditClose}
					resetPostDetails={props.resetPostDetails}
					postId={props.postId}
					gigType={"post"}
					editPost={false}
					setAlertMsg={setAlertMsg}
					setAlertStatus={setAlertStatus}
					setShowAlert={setShowAlert}
				/>
			)}
		</>
	);
};

export default CreatePostNew;
